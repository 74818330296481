/* eslint-disable complexity */

import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* React Router */
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import LoginLoader from './LoginLoader';

/* Public */
// import TCITLogo from '/assets/images/TcitLogo.png';
const EBCOLogo = `${window.location.origin}/images/logo-ebco.png`;

function LoginForm(props) {
  const { goToHome, controls, errors, onChangeText, handleLogin, loading } = props;

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const toggleLoginLoader = useSelector((state) => state.login.loading);

  return (
    <form onSubmit={(evt) => evt.preventDefault()}>
      <LoginLoader open={toggleLoginLoader} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ margin: 0, height: '100vh' }}>
        <Grid item md={6} lg={4} xs={12}>
          <Card elevation={2} style={{ margin: '0px 10px' }}>
            <CardContent>
              <Grid container justifyContent="center">
                <Grid container justifyContent="center" sx={{ mb: 7, mt: 1 }}>
                  <CardMedia
                    onClick={goToHome}
                    component="img"
                    style={{
                      height: '120px',
                      width: '120px',
                      cursor: 'pointer',
                      objectFit: 'contain',
                      background: '#383B3C',
                      padding: '10px',
                    }}
                    image={EBCOLogo}
                    title="TCIT"
                  />
                </Grid>
                <Grid item xs={10} lg={7} sx={{ pb: 4 }}>
                  <TextField
                    autoFocus
                    inputProps={{ 'data-testid': 'text-field-email' }}
                    label="Correo electrónico"
                    id="email"
                    value={controls.email}
                    onChange={onChangeText}
                    fullWidth
                    error={!!errors.email.message}
                    helperText={errors.email.message}
                  />
                </Grid>
                <Grid item xs={10} lg={7} sx={{ pb: 3 }}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="password">Contraseña</InputLabel>
                    <OutlinedInput
                      type={showPassword ? 'text' : 'password'}
                      inputProps={{ 'data-testid': 'text-field-password' }}
                      label="Contraseña"
                      onChange={onChangeText}
                      value={controls.password}
                      id="password"
                      fullWidth
                      error={!!errors.password.message}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {!!errors.password.message && (
                      <FormHelperText error id="username-error">
                        {errors.password.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {controls.error && (
                  <Grid item xs={10}>
                    <Typography style={{ color: 'red' }} align="center" variant="caption">
                      Credenciales inválidas
                    </Typography>
                  </Grid>
                )}
                <Grid container direction="row" justifyContent="center">
                  <Grid item>
                    <LoadingButton
                      data-testid="button-login"
                      onClick={handleLogin}
                      type="submit"
                      variant="text"
                      sx={{ marginTop: '5px', color: 'black', fontWeight: '500' }}
                      loadingPosition="start"
                      loading={loading}>
                      Ingresar
                    </LoadingButton>
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" sx={{ mt: 4, ml: 2 }}>
                  <Button
                    variant="text"
                    onClick={() => navigate('/recovery')}
                    sx={{ color: 'black' }}>
                    ¿Olvidaste tu contraseña?
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}

LoginForm.propTypes = {
  goToHome: PropTypes.func.isRequired,
  controls: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onChangeText: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

LoginForm.defaultProps = {
  loading: false,
};

export default LoginForm;
