const BASE = '[Centralization]';

const CentralizationTypes = {
  CREATE_CENTRALIZATION: `${BASE} CREATE_CENTRALIZATION`,
  CREATE_CENTRALIZATION_SUCCESS: `${BASE} CREATE_CENTRALIZATION_SUCCESS`,
  CREATE_CENTRALIZATION_FAIL: `${BASE} CREATE_CENTRALIZATION_FAIL`,
  CHECK_CENTRALIZATION_STATUS: `${BASE} CHECK_CENTRALIZATION_STATUS`,
  FETCH_CENTRALIZATIONS: `${BASE} FETCH_CENTRALIZATIONS`,
  FETCH_CENTRALIZATIONS_SUCCESS: `${BASE} FETCH_CENTRALIZATIONS_SUCCESS`,
  FETCH_CENTRALIZATIONS_FAIL: `${BASE} FETCH_CENTRALIZATIONS_FAIL`,
  OPEN_CREATE_CENTRALIZATION_MODAL: `${BASE} OPEN_CREATE_CENTRALIZATION_MODAL`,
  CLOSE_CREATE_CENTRALIZATION_MODAL: `${BASE} CLOSE_CREATE_CENTRALIZATION_MODAL`,
  CENTRALIZATION_TYPE: `${BASE} CENTRALIZATION_TYPE`,
  SET_SELECT_COMPANY: `${BASE} SET_SELECT_COMPANY`,
  SET_SELECT_PERIOD: `${BASE} SET_SELECT_PERIOD`,
  CHANGE_PAGE: `${BASE} CHANGE_PAGE`,
  CHANGE_SIZE: `${BASE} CHANGE_SIZE`,
  SHOW_ERROR: `${BASE} SHOW_ERROR`,
  HIDE_ERROR: `${BASE} HIDE_ERROR`,
  GET_LAST_PERIOD: `${BASE} GET_LAST_PERIOD`,
  GET_LAST_PERIOD_SUCCESS: `${BASE} GET_LAST_PERIOD_SUCCESS`,
  GET_LAST_PERIOD_FAIL: `${BASE} GET_LAST_PERIOD_FAIL`,
  CHECK_CENTRALIZATION_EXISTENCE: `${BASE} CHECK_CENTRALIZATION_EXISTENCE`,
  CHECK_CENTRALIZATION_EXISTENCE_SUCCESS: `${BASE} CHECK_CENTRALIZATION_EXISTENCE_SUCCESS`,
  CHECK_CENTRALIZATION_EXISTENCE_FAIL: `${BASE} CHECK_CENTRALIZATION_EXISTENCE_FAIL`,
  RESET_CENTRALIZATION_EXISTENCE: `${BASE} RESET_CENTRALIZATION_EXISTENCE`,
};

export default CentralizationTypes;
