import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, Typography, Alert, Snackbar } from '@mui/material';
import CreateCentralizationModal from 'content/features/centralizations/components/CreateCentralizationModal';
import ComponentLayout from '../../containers/Layout/ComponentLayout';
import CentralizationsTable from '../../features/centralizations/components/CentralizationsTable';
import {
  fetchCentralizations,
  openCreateCentralizationModal,
  showError,
  hideError,
  getAllPeriods,
} from '../../features/centralizations/centralizations.actions';
import { fetchCompanies } from '../../features/companies/companies.actions';

function Centralizations() {
  const dispatch = useDispatch();
  const { centralizations, pagination, error } = useSelector((state) => state.centralizations);
  const companies = useSelector((state) => state.companies.companies);

  const handleOpenModal = () => {
    dispatch(openCreateCentralizationModal());
  };

  useEffect(() => {
    if (error) {
      console.log('error', error);
      dispatch(showError(error));
    }
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideError());
  };

  useEffect(() => {
    dispatch(fetchCompanies());
    dispatch(getAllPeriods());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCentralizations({ page: pagination.page, size: pagination.size }));
  }, []);

  return (
    <ComponentLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Listado de centralizaciones
          </Typography>
          <Typography variant="body1" gutterBottom>
            Identifica las centralizaciones realizadas y realiza nuevas centralizaciones, ya sea
            para remuneraciones o finiquitos.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              sx={{ marginLeft: 2 }}
              onClick={handleOpenModal}>
              Crear centralización
            </Button>
            <CreateCentralizationModal companies={companies} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CentralizationsTable centralizations={centralizations} pagination={pagination} />
        </Grid>
      </Grid>
      <Snackbar
        open={error}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </ComponentLayout>
  );
}

export default Centralizations;
