const BASE = '[Liquidations]';

const LiquidationsTypes = {
  FETCH_DATA: `${BASE} FETCH_DATA`,
  FETCH_DATA_SUCCESS: `${BASE} FETCH_DATA_SUCCESS`,
  FETCH_DATA_FAIL: `${BASE} FETCH_DATA_FAIL`,
  CHANGE_CONTROLS: `${BASE} CHANGE_CONTROLS`,
  ADD_HEADER: `${BASE} ADD_HEADER`,
  REMOVE_HEADER: `${BASE} REMOVE_HEADER`,
};

export default LiquidationsTypes;
