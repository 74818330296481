import { indigo } from '@mui/material/colors';

/**
 * @type {import('@mui/material').ThemeOptions}
 */
const defaultTheme = {
  palette: {
    primary: {
      main: '#2F80ED',
      contrastText: '#fff',
    },
    secondary: {
      main: '#818181',
      contrastText: '#fff',
    },
    title: {
      main: indigo[900],
      contrastText: '#fff',
    },
    text: {
      title: 'rgb(112, 76, 182)',
      number: 'rgb(81,82,82)',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'bold',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontSize: '1.5rem',
          fontWeight: 500,
        },
        subtitle1: {
          fontSize: '1.25rem',
          fontWeight: 500,
        },
      },
    },
  },
};

export default defaultTheme;
