import { createReducer } from '@reduxjs/toolkit';
import { addHeader, removeHeader } from './liquidations.actions';

const initialState = {
  loaders: {
    find: false,
  },
  controls: {
    headers: [],
  },
};

const LiquidationsReducer = createReducer(initialState, (builder) => {
  builder.addCase(addHeader, (state, action) => {
    state.controls.headers = [...state.controls.headers, action.payload];
  });

  builder.addCase(removeHeader, (state, action) => {
    state.controls.headers = action.payload;
  });
});

export default LiquidationsReducer;
