import { createReducer } from '@reduxjs/toolkit';
import {
  changeControls,
  changeControlsSearch,
  changeFilterControls,
  changePage,
  changeSize,
  checkSimulationResponse,
  checkSimulationResponseFail,
  checkSimulationResponseSuccess,
  closeSimulationprocessDialog,
  clearFilters,
  getLiquidations,
  getLiquidationsFail,
  getLiquidationsSuccess,
  openNextPageDialog,
  resetSimulationItems,
  setSimulationItems,
  setSimulationStatus,
  setSorting,
  simulateLiquidation,
  simulateLiquidationFail,
  simulateLiquidationSuccess,
  toggleConfirmationDialog,
  toggleOvertimeFormatError,
} from './searchLiquidations.actions';

const initialState = {
  loaders: {
    find: false,
    simulation: false,
  },
  controls: {
    buildCode: [],
    simulationItems: {},
  },
  filters: {
    // buildCodesData: [],
    employeeCodeSheet: '',
    rut: '',
    position: '',
  },
  sorting: {
    key: '',
    direction: false,
  },
  pagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  resultIds: [],
  activePeriod: '',
  period: {},
  statusMsg: '',
  headers: [],
  timestamp: '',
  initiateGetSimulationResponse: false,
  nextPageDialog: false,
  searchedCode: '',
  overtimeErrorDialog: false,
  confirmationDialog: false,
  simulationProcessDialog: false,
};

const assignOvertime = ({ itemsUpdated, employeeId, itemCode, value }) => ({
  ...itemsUpdated,
  [employeeId]: {
    ...itemsUpdated[employeeId],
    overtimes: {
      ...itemsUpdated[employeeId]?.overtimes,
      [itemCode]: value,
    },
  },
});
const assignItem = ({ itemsUpdated, employeeId, itemCode, value }) => ({
  ...itemsUpdated,
  [employeeId]: {
    ...itemsUpdated[employeeId],
    items: {
      ...itemsUpdated[employeeId]?.items,
      [itemCode]: value,
    },
  },
});
function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const SearchLiquidationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getLiquidations, (state) => {
      state.loaders.find = true;
      // state.filters.buildCodesData = [];
      state.searchedCode = '';
    })
    .addCase(getLiquidationsSuccess, (state, action) => {
      state.loaders.find = false;
      state.pagination.count = action.payload.count;
      // state.pagination.page = action.payload.pagination.page;
      state.resultIds = action.payload.resultIds;
      state.activePeriod = action.payload.activePeriod;
      state.period = action.payload.period;
      // state.filters.buildCodesData = action.payload.buildCodeData;
      if (action.payload.headers) state.headers = action.payload.headers;
      state.searchedCode = action.payload.buildCode;
    })
    .addCase(getLiquidationsFail, (state) => {
      state.loaders.find = false;
      state.searchedCode = '';
      state.controls = {
        ...initialState.controls,
      };
    })
    .addCase(changeControls, (state, action) => {
      state.controls = {
        ...state.controls,
        ...action.payload,
      };
    })
    .addCase(changeControlsSearch, (state, action) => {
      state.controls = {
        ...state.controls,
        ...action.payload,
      };
      state.filters = initialState.filters;
      state.sorting = initialState.sorting;
      state.pagination = initialState.pagination;
      state.resultIds = [];
      state.controls.simulationItems = {};
    })
    .addCase(changeFilterControls, (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    })
    .addCase(changePage, (state, action) => {
      state.pagination.page = action.payload;
    })
    .addCase(changeSize, (state, action) => {
      state.pagination.size = action.payload;
    })
    .addCase(clearFilters, (state) => {
      state.filters = initialState.filters;
      state.sorting = initialState.sorting;
      state.pagination.count = initialState.pagination.count;
      state.pagination.page = initialState.pagination.page;
      state.controls.simulationItems = {};
    })
    .addCase(setSorting, (state, action) => {
      state.sorting = {
        key: action.payload.code,
        direction: !state.sorting.direction,
      };
    })
    .addCase(setSimulationItems, (state, action) => {
      let itemsUpdated = state.controls.simulationItems;
      // eslint-disable-next-line complexity
      action.payload.forEach((row) => {
        const { overtime, employeeId, itemCode, value, isOriginalAmount } = row;

        if (overtime) {
          if (isOriginalAmount) {
            delete itemsUpdated[employeeId].overtimes[itemCode];
          } else itemsUpdated = assignOvertime({ itemsUpdated, employeeId, itemCode, value });
        } else {
          // eslint-disable-next-line no-lonely-if
          if (isOriginalAmount) {
            delete itemsUpdated[employeeId]?.items[itemCode];
          } else itemsUpdated = assignItem({ itemsUpdated, employeeId, itemCode, value });
        }
      });
      // eslint-disable-next-line complexity
      itemsUpdated = Object.keys(itemsUpdated).reduce((acc, employeeId) => {
        if (
          !isEmpty(itemsUpdated[employeeId].items || {}) ||
          !isEmpty(itemsUpdated[employeeId].overtimes || {})
        ) {
          // eslint-disable-next-line no-param-reassign
          acc[employeeId] = itemsUpdated[employeeId];
        }
        return acc;
      }, {});
      state.controls.simulationItems = itemsUpdated;
    })
    .addCase(simulateLiquidation, (state, action) => {
      state.loaders.find = true;
      state.loaders.simulation = true;
      state.simulationProcessDialog = true;
      state.timestamp = action.payload.timestamp;
    })
    .addCase(simulateLiquidationSuccess, (state) => {
      state.loaders.find = false;
      state.loaders.simulation = false;
      state.initiateGetSimulationResponse = true;
      /* state.pagination.count = action.payload.count;
      state.resultIds = action.payload.resultIds;
      state.statusMsg = '';
      state.controls.simulationItems = {}; */
    })
    .addCase(simulateLiquidationFail, (state) => {
      state.loaders.find = false;
      state.loaders.simulation = false;
      state.initiateGetSimulationResponse = false;
      state.controls.simulationItems = {};
    })
    // eslint-disable-next-line complexity
    .addCase(setSimulationStatus, (state, action) => {
      state.statusMsg = action.payload.statusMsg;
      if (action.payload.statusMsg === 'Guardado completo' && action.payload.saveSimulation)
        state.controls.simulationItems = {};
    })
    .addCase(checkSimulationResponse, (state) => {
      state.loaders.find = true;
      state.loaders.simulation = true;
    })
    .addCase(checkSimulationResponseSuccess, (state, action) => {
      state.loaders.find = false;
      state.loaders.simulation = false;
      state.initiateGetSimulationResponse = false;
      state.resultIds = action.payload.resultIds;
      state.timestamp = '';
    })
    .addCase(checkSimulationResponseFail, (state) => {
      state.timestamp = '';
      state.loaders.find = false;
      state.loaders.simulation = false;
    })
    .addCase(closeSimulationprocessDialog, (state) => {
      state.statusMsg = '';
      state.simulationProcessDialog = false;
    })
    .addCase(openNextPageDialog, (state, action) => {
      state.nextPageDialog = action.payload;
    })
    .addCase(toggleOvertimeFormatError, (state, action) => {
      state.overtimeErrorDialog = action.payload;
    })
    .addCase(toggleConfirmationDialog, (state, action) => {
      state.confirmationDialog = action.payload;
    })
    .addCase(resetSimulationItems, (state) => {
      state.controls.simulationItems = initialState.controls.simulationItems;
    });
});

export default SearchLiquidationsReducer;
