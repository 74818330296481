/* eslint-disable complexity */
import React from 'react';
import { Step, StepLabel, Stepper, Typography, Grid } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

function StatusIndicator(props) {
  const { recordLineLogs } = props;

  const recordLineLogsOrdered = [...recordLineLogs.logs].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  if (!recordLineLogs || !recordLineLogs.logs || recordLineLogs.logs.length === 0) {
    return (
      <Typography variant="body1" sx={{ margin: 2 }}>
        Debe validar para poder obtener los estados de las validaciones.
      </Typography>
    );
  }

  return (
    <Stepper orientation="vertical" nonLinear>
      {recordLineLogsOrdered.map((log) => (
        <Step key={log.id} completed active>
          <StepLabel icon={<CircleIcon sx={{ color: log.validated ? 'green' : 'red' }} />}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="body1">{log.title}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{log.description}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" display="block" sx={{ color: 'grey' }}>
                  {new Date(log.createdAt).toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default StatusIndicator;
