import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Box, Typography, Button, Divider } from '@mui/material';
import { Send } from '@mui/icons-material';
import { formatCurrency } from 'utils/functions';
import { closeCentralizeModal, centralizeData } from '../centralizationDetails.actions';

function CentralizateModal(props) {
  const dispatch = useDispatch();
  const { data } = props;
  const { date, type } = data;

  const open = useSelector((state) => state.centralizationDetails.isCentralizeModalOpen);
  const { record, pagination, company, totalBudget } = useSelector(
    (state) => state.centralizationDetails,
  );

  const handleClose = () => {
    dispatch(closeCentralizeModal());
  };

  const handleCentralizeData = () => {
    dispatch(centralizeData({ recordId: record.id, page: pagination.page, size: pagination.size }));
    dispatch(closeCentralizeModal());
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    textAlign: 'center',
  };

  const rowStyle = {
    display: 'flex',
    alignItems: 'center',
    my: 1,
    justifyContent: 'space-between',
  };

  const dividerStyle = {
    margin: '0 auto',
  };

  const titleStyle = {
    textAlign: 'left',
    fontWeight: 'bold',
    flex: 1,
  };

  // const contentStyle = {
  //   textAlign: 'right',
  //   flex: 'none',
  // };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Send sx={{ fontSize: 40, color: 'primary.main' }} />
        <Typography variant="h6" component="h2" sx={{ mb: 1, fontWeight: 'bold' }}>
          Enviar centralización
        </Typography>
        <Typography sx={{ mb: 2 }}>¿Estás seguro que deseas enviar la centralización?</Typography>

        <Box sx={rowStyle}>
          <Typography sx={titleStyle}>Empresa:</Typography>
          <Typography>{company.name}</Typography>
        </Box>
        <Divider sx={dividerStyle} />
        <Box sx={rowStyle}>
          <Typography sx={titleStyle}>RUT Empresa:</Typography>
          <Typography>{company.rut}</Typography>
        </Box>
        <Divider sx={dividerStyle} />
        <Box sx={rowStyle}>
          <Typography sx={titleStyle}>Periodo:</Typography>
          <Typography>{date}</Typography>
        </Box>
        <Divider sx={dividerStyle} />
        <Box sx={rowStyle}>
          <Typography sx={titleStyle}>Tipo:</Typography>
          <Typography>{type}</Typography>
        </Box>
        <Divider sx={dividerStyle} />
        <Box sx={rowStyle}>
          <Typography sx={titleStyle}>Cant. de proyectos:</Typography>
          <Typography>{record?.totalProjects}</Typography>
        </Box>
        <Divider sx={dividerStyle} />
        <Box sx={rowStyle}>
          <Typography sx={titleStyle}>Total a centralizar:</Typography>
          <Typography>{formatCurrency(totalBudget.totalCredit)}</Typography>
        </Box>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleCentralizeData} color="primary" variant="contained" sx={{ ml: 2 }}>
            Enviar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default CentralizateModal;
