import { createAction } from '@reduxjs/toolkit';
import BuildingCodesTypes from './buildingCodes.types';

export const fetchData = createAction(BuildingCodesTypes.FETCH_DATA);
export const fetchDataSuccess = createAction(BuildingCodesTypes.FETCH_DATA_SUCCESS, (payload) => ({
  payload: {
    ...payload,
  },
}));
export const fetchDataFail = createAction(BuildingCodesTypes.FETCH_DATA_FAIL);
export const changePage = createAction(BuildingCodesTypes.CHANGE_PAGE, (payload) => ({
  payload,
}));
export const changeSize = createAction(BuildingCodesTypes.CHANGE_SIZE, (payload) => ({
  payload,
}));

export const changeControls = createAction(
  BuildingCodesTypes.CHANGE_CONTROLS,
  ({ name, value }) => ({
    payload: {
      [name]: value,
    },
  }),
);

export const resetControls = createAction(BuildingCodesTypes.RESET_CONTROLS);
export const toggleDialog = createAction(BuildingCodesTypes.TOGGLE_DIALOG);
export const handleUsersSelection = createAction(
  BuildingCodesTypes.HANDLE_USERS_SELECTION,
  (payload) => ({
    payload,
  }),
);
export const closeDialog = createAction(BuildingCodesTypes.CLOSE_DIALOG);
export const assignBuildingCodeUsers = createAction(
  BuildingCodesTypes.ASSIGN_BUILDING_CODE_USERS,
  (payload) => ({
    payload,
  }),
);
export const assignBuildingCodeUsersSuccess = createAction(
  BuildingCodesTypes.ASSIGN_BUILDING_CODE_USERS_SUCCESS,
  (payload) => ({
    payload,
  }),
);
export const assignBuildingCodeUsersFail = createAction(
  BuildingCodesTypes.ASSIGN_BUILDING_CODE_USERS_FAIL,
  (payload) => ({
    payload,
  }),
);

const BuildingCodesActions = {
  fetchData,
  fetchDataSuccess,
  fetchDataFail,
  changePage,
  changeSize,
  resetControls,
  toggleDialog,
  handleUsersSelection,
  closeDialog,
  assignBuildingCodeUsers,
  assignBuildingCodeUsersSuccess,
  assignBuildingCodeUsersFail,
};

export default BuildingCodesActions;
