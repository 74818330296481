import { Button, Grid, TextField } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { changeControls, fetchData, resetControls } from '../buildingCodes.actions';

function Filters(props) {
  const dispatch = useDispatch();
  const { filters, pagination, loaders } = props;
  const { name, code } = filters;
  const { page } = loaders;

  const handleChange = (e) => {
    dispatch(changeControls({ name: e.target.name, value: e.target.value }));
  };

  const filterData = () => dispatch(fetchData({ ...pagination, ...filters }));

  const resetFilters = () => dispatch(resetControls());

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
      sx={{ mt: 1, mb: 2 }}>
      <Grid item>Filtros</Grid>
      <Grid item xs={12} sm={12} lg={3}>
        <TextField
          label="Nombre"
          name="name"
          value={name}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={3}>
        <TextField
          label="Código"
          name="code"
          value={code}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={12} lg={3} sx={{ ml: 'auto' }}>
        <Grid container direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
          <Grid xs={12} sm={12} lg={5}>
            <Button
              variant="outlined"
              size="large"
              fullWidth
              sx={{ mb: 1 }}
              onClick={resetFilters}
              disabled={page}>
              Limpiar
            </Button>
          </Grid>
          <Grid xs={12} sm={12} lg={6}>
            <LoadingButton
              loading={page}
              loadingPosition="start"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              sx={{ mb: 1 }}
              onClick={filterData}>
              Filtrar
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Filters;
