import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function OvertimeFormatDialog(props) {
  const { open, toggleDialog } = props;
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogContent sx={{ p: 3, paddingBottom: 0 }}>
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={3}>
            <InfoOutlinedIcon color="primary" sx={{ fontSize: 100 }} />
          </Grid>
          <Grid xs={12} sm={9}>
            <Typography variant="h6">
              Algunos valores no se copiaron porque no cumplen con el formato, los valores
              ingresados en campos de horas extras deben ser menor a 1000.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => toggleDialog(false)}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OvertimeFormatDialog;
