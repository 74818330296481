import { createAction } from '@reduxjs/toolkit';
import CentralizationDetailsTypes from './centralizationDetails.types';

export const fetchCentralizationDetails = createAction(
  CentralizationDetailsTypes.FETCH_CENTRALIZATION_DETAILS,
  (payload) => ({ payload }),
);

export const fetchCentralizationDetailsSuccess = createAction(
  CentralizationDetailsTypes.FETCH_CENTRALIZATION_DETAILS_SUCCESS,
  (payload) => ({ payload }),
);

export const fetchCentralizationDetailsFail = createAction(
  CentralizationDetailsTypes.FETCH_CENTRALIZATION_DETAILS_FAIL,
);

export const changePage = createAction(CentralizationDetailsTypes.CHANGE_PAGE, (payload) => ({
  payload,
}));

export const changeSize = createAction(CentralizationDetailsTypes.CHANGE_SIZE, (payload) => ({
  payload,
}));

export const validateData = createAction(CentralizationDetailsTypes.VALIDATE_DATA, (payload) => ({
  payload,
}));

export const validateDataSuccess = createAction(
  CentralizationDetailsTypes.VALIDATE_DATA_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const validateDataFail = createAction(
  CentralizationDetailsTypes.VALIDATE_DATA_FAIL,
  (payload) => ({
    payload,
  }),
);

export const centralizeData = createAction(
  CentralizationDetailsTypes.CENTRALIZE_DATA,
  (payload) => ({
    payload,
  }),
);

export const centralizeDataSuccess = createAction(
  CentralizationDetailsTypes.CENTRALIZE_DATA_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const centralizeDataFail = createAction(
  CentralizationDetailsTypes.CENTRALIZE_DATA_FAIL,
  (payload) => ({
    payload,
  }),
);

export const openCentralizeModal = createAction(CentralizationDetailsTypes.OPEN_CENTRALIZE_MODAL);

export const closeCentralizeModal = createAction(CentralizationDetailsTypes.CLOSE_CENTRALIZE_MODAL);

export const showNotification = createAction(
  CentralizationDetailsTypes.SHOW_NOTIFICATION,
  (payload) => ({
    payload,
  }),
);

export const hideNotification = createAction(CentralizationDetailsTypes.HIDE_NOTIFICATION);

export const getCompany = createAction(CentralizationDetailsTypes.GET_COMPANY, (payload) => ({
  payload,
}));

export const getCompanySuccess = createAction(
  CentralizationDetailsTypes.GET_COMPANY_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const getCompanyFail = createAction(
  CentralizationDetailsTypes.GET_COMPANY_FAIL,
  (payload) => ({
    payload,
  }),
);

export const getPeriod = createAction(CentralizationDetailsTypes.GET_PERIOD, (payload) => ({
  payload,
}));

export const getPeriodSuccess = createAction(
  CentralizationDetailsTypes.GET_PERIOD_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const getPeriodFail = createAction(
  CentralizationDetailsTypes.GET_PERIOD_FAIL,
  (payload) => ({
    payload,
  }),
);

export const resetPagination = createAction(CentralizationDetailsTypes.RESET_PAGINATION);

export const deleteCentralization = createAction(
  CentralizationDetailsTypes.DELETE_CENTRALIZATION,
  (payload) => ({ payload }),
);

export const resetState = createAction(CentralizationDetailsTypes.RESET_STATE);

const CentralizationDetailsActions = {
  fetchCentralizationDetails,
  fetchCentralizationDetailsSuccess,
  fetchCentralizationDetailsFail,
  changePage,
  changeSize,
  validateData,
  centralizeData,
  openCentralizeModal,
  closeCentralizeModal,
  showNotification,
  hideNotification,
  getCompany,
  getCompanySuccess,
  getCompanyFail,
  getPeriod,
  getPeriodSuccess,
  getPeriodFail,
  resetPagination,
  deleteCentralization,
  resetState,
};

export default CentralizationDetailsActions;
