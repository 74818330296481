/* eslint-disable complexity */

import React, { useMemo } from 'react';
import { Alert, Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';

import 'moment/locale/es';

import { useDispatch, useSelector } from 'react-redux';
import { changeControlsSearch } from '../searchLiquidations.actions';

function SearchForm({ objects, fetchLiquidations }) {
  const dispatch = useDispatch();
  const {
    controls: { buildCode },
    loaders,
    activePeriod,
  } = useSelector((state) => state.searchLiquidations);
  console.log({ objects });
  const { buildingCodes } = objects;
  const showLiquidations = buildCode.length && activePeriod !== '';

  const handleChange = (payload) => {
    const lastValue = payload.length ? [payload[0]] : [];
    dispatch(changeControlsSearch({ name: 'buildCode', value: lastValue }));
  };

  const buildingCodesList = useMemo(() => Object.values(buildingCodes ?? {}), [buildingCodes]);

  return (
    <Grid container direction="row" alignItems="center">
      <Grid container sx={{ pt: 3, pb: 2 }} alignItems="center">
        <Typography variant="subtitle1" gutterBottom sx={{ mr: '40px' }}>
          Código de obra:
        </Typography>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={5}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Grid container>
            <Grid xs={10}>
              <Autocomplete
                multiple
                id="tags-standard"
                disableListWrap
                value={buildCode}
                onChange={(event, value) => handleChange(value)}
                options={buildingCodesList}
                clearIcon={false}
                getOptionLabel={(option) =>
                  `${option?.code} - ${option?.name} (${option.bukAreaId})`
                }
                renderOption={(props, option) => (
                  /* eslint-disable-next-line  */
                  <Box component="li" {...props}>
                    {option.code} - {option.name}
                  </Box>
                )}
                noOptionsText="No hay códigos de obra disponibles"
                sx={{
                  mr: 2,
                }}
                renderInput={(params) => (
                  <TextField
                    /* eslint-disable-next-line  */
                    {...params}
                    variant="outlined"
                    name="buildCode"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid xs={2}>
              <LoadingButton
                loading={loaders.find}
                disabled={!buildCode.length}
                loadingPosition="center"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={fetchLiquidations}
                sx={{ height: '100%' }}>
                <SearchIcon sx={{ padding: '0px', margin: '0px' }} />
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>

        {!showLiquidations && (
          <Grid xs={12} sx={{ mt: 5 }}>
            <Alert severity="info">
              Ingrese código(s) de obra para la simulación de remuneraciones.
            </Alert>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default SearchForm;
