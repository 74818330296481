import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import React, { useMemo } from 'react';
import { LoadingButton } from '@mui/lab';

function AssingUserDialog(props) {
  const { controls, users, onChangeAutocomplete, handleCloseDialog, assingUsers, loaders } = props;
  const { openDialog, selectedCode, selectedUsers } = controls;
  const { save } = loaders;

  const updateUsers = () =>
    assingUsers({
      buildCodeId: selectedCode.id,
      userIds: selectedUsers ? selectedUsers.map((item) => item.id) : [],
    });

  const usersList = useMemo(() => Object.values(users), [users]);

  const selectedValues = useMemo(
    () => usersList.filter((user) => selectedUsers.some((su) => su.id === user.id)),
    [usersList, selectedUsers],
  );

  return (
    <Dialog
      open={openDialog}
      onClose={() => handleCloseDialog({})}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Asignar usuario a código de obra</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid container direction="row" sx={{ mb: 1 }}>
            <Grid xs={4}>Área :</Grid>
            <Grid xs={8}>
              <Chip label={selectedCode.name} size="small" color="primary" />
            </Grid>
          </Grid>
          <Grid container direction="row" sx={{ mb: 1 }}>
            <Grid xs={4}>Código:</Grid>
            <Grid xs={8}>
              <Chip label={selectedCode.code} size="small" color="primary" />
            </Grid>
          </Grid>
          <Grid container direction="column" sx={{ mb: 1, mt: 3 }}>
            <Grid xs={12}>Seleccionar usuarios:</Grid>
            <Grid xs={12}>
              <Autocomplete
                sx={{ mt: 1 }}
                multiple
                disabled={save}
                onChange={(event, value) => onChangeAutocomplete(value)}
                id="tags-filled"
                value={selectedValues}
                options={usersList}
                getOptionLabel={(option) => `${option.name} - ${option.email}`}
                noOptionsText="No hay usuarios disponibles"
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      avatar={<Avatar>{option.name[0].toUpperCase()}</Avatar>}
                      label={`${option.name} - ${option.email}`}
                      variant="outlined"
                      /* eslint-disable-next-line  */
                      {...getTagProps({ index })}
                      color="primary"
                      deleteIcon={<DeleteIcon />}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    /* eslint-disable-next-line  */
                    {...params}
                    variant="outlined"
                    placeholder="Seleccionar usuario..."
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseDialog({})} disabled={save}>
          cerrar
        </Button>
        <LoadingButton
          loading={save}
          autoFocus
          onClick={updateUsers}
          variant="text"
          loadingPosition="center">
          guardar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AssingUserDialog;
