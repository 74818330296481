import React from 'react';
import { Button, Grid, TextField, useMediaQuery } from '@mui/material';
import {
  changeFilterControls,
  clearFilters,
} from 'content/features/searchLiquidations/searchLiquidations.actions';
import { useDispatch } from 'react-redux';

function Filters(props) {
  const dispatch = useDispatch();
  const { filters, filterLiquidations } = props;
  const { employeeCodeSheet, rut, position } = filters;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const handleChange = (e) => {
    if (e.target.name === 'position')
      dispatch(changeFilterControls({ name: e.target.name, value: e.target.value }));
    else dispatch(changeFilterControls({ name: e.target.name, value: e.target.value.trim() }));
  };

  const handleClearFilters = () => {
    dispatch(clearFilters());
    filterLiquidations();
  };

  const numberFieldStyles = {
    'input::-webkit-outer-spin-button , input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    'input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  };

  const filterOnEnter = (evt) => {
    if (evt.code === 'Enter') {
      filterLiquidations();
    }
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
      sx={{ mt: 1, mb: 2 }}>
      <Grid item xs={12} lg={1}>
        Filtros
      </Grid>

      <Grid item xs={12} sm={12} lg={2}>
        <TextField
          id="outlined-basic"
          name="employeeCodeSheet"
          value={employeeCodeSheet}
          label="Ficha empleado"
          variant="outlined"
          onChange={handleChange}
          sx={numberFieldStyles}
          fullWidth
          onKeyDown={filterOnEnter}
        />
      </Grid>

      <Grid item xs={12} sm={12} lg={2}>
        <TextField
          id="outlined-basic"
          name="rut"
          value={rut}
          label="Rut"
          variant="outlined"
          onChange={handleChange}
          fullWidth
          onKeyDown={filterOnEnter}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={2}>
        <TextField
          id="outlined-basic"
          name="position"
          value={position}
          label="Cargo"
          variant="outlined"
          onChange={handleChange}
          fullWidth
          onKeyDown={filterOnEnter}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          size="large"
          fullWidth={isMobile}
          sx={{ mr: 1 }}
          onClick={handleClearFilters}>
          Limpiar
        </Button>
        <Button variant="contained" size="large" fullWidth={isMobile} onClick={filterLiquidations}>
          Filtrar
        </Button>
      </Grid>
    </Grid>
  );
}

export default Filters;
