/* eslint-disable complexity */
import { takeLatest, put } from 'redux-saga/effects';
import apiRequest from 'utils/api';
import { fetchCompanies, fetchCompaniesSuccess, fetchCompaniesFail } from './companies.actions';

function* handleFetchCompanies() {
  try {
    const response = yield apiRequest('api/v1/companies', { method: 'get' });
    const companies = [];
    const codes = [1200, 1000, 1400];
    // eslint-disable-next-line array-callback-return
    response.map((company) => {
      if (codes.includes(company.code)) {
        companies.push(company);
      }
    });

    yield put(fetchCompaniesSuccess(companies));
  } catch (error) {
    yield put(fetchCompaniesFail(error.toString()));
  }
}

export default function* companiesSaga() {
  yield takeLatest(fetchCompanies, handleFetchCompanies);
}
