/* eslint-disable complexity */

import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

function Navegation() {
  const location = useLocation();
  const navigate = useNavigate();

  const simulatorNavigationPath = [
    { name: 'Inicio', path: '/' },
    { name: 'Búsqueda', path: '/simulador' },
    { name: 'Simulador', path: '/liquidaciones' },
  ];

  const breadcrumbs = simulatorNavigationPath.map((item) => (
    <Typography
      variant="body1"
      component="h2"
      sx={{
        cursor: 'pointer',
        color: item.path === location.pathname ? 'primary.main' : 'text.secondary',
        '&:hover': { textDecoration: item.path === location.pathname ? 'none' : 'underline' },
      }}
      onClick={() => navigate(item.path)}>
      {item.name}
    </Typography>
  ));

  return (
    <Stack spacing={2} sx={{ mb: 2 }}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}

export default Navegation;
