/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable complexity */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  TextField,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { convertDate } from 'utils/functions';
import {
  centralizationType,
  checkCentralizationExistence,
  closeCreateCentralizationModal,
  resetCentralizationExistence,
  // createCentralization,
  setSelectCompany,
  setSelectPeriod,
} from '../centralizations.actions';

const TERMINATION = 'Finiquitos';
const CENTRALIZATION = 'Centralizacion';

function CentralizationCreationModal({ companies }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    centralizationType: selectedType,
    selectedCompany,
    isCreateModalOpen: open,
    createdRecordId,
    isLoading,
    error,
    periods,
    selectedPeriod,
    centralizationChecked,
  } = useSelector((state) => state.centralizations);

  let formattedOptions = [];

  const handlePeriodChange = (event, value) => {
    if (value !== null) {
      dispatch(setSelectPeriod(value.value));
    } else {
      dispatch(setSelectPeriod(null));
    }
  };

  const handleCompanyChange = (event, value) => {
    dispatch(setSelectCompany(value));
  };

  const handleTypeChange = (event) => {
    dispatch(centralizationType(event.target.value));
  };

  const onClose = () => {
    dispatch(closeCreateCentralizationModal());
    dispatch(resetCentralizationExistence());
  };

  const handleCreate = () => {
    if (selectedCompany && selectedType) {
      dispatch(
        checkCentralizationExistence({
          period: selectedPeriod,
          type: selectedType,
          company: selectedCompany,
        }),
      );
    }
  };

  useEffect(() => {
    if (Object.keys(centralizationChecked).length > 0) {
      navigate(`/centralizations/${centralizationChecked.id}`);
      onClose();
    }
  }, [centralizationChecked]);

  useEffect(() => {
    if (createdRecordId) {
      navigate(`/centralizations/${createdRecordId}`);
      dispatch(closeCreateCentralizationModal());
    }
  }, [createdRecordId, dispatch, navigate]);

  useEffect(() => {
    if (error) {
      console.log('error', error);
      dispatch(closeCreateCentralizationModal());
    }
  }, [error]);

  if (Array.isArray(periods)) {
    formattedOptions = periods.map((period) => ({
      value: period,
      label: convertDate(period.month),
      id: period.id,
    }));
  } else {
    formattedOptions = [];
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: '80%', md: '70%', lg: '50%', xl: '40%' },
    maxWidth: '100%',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
          Crear centralización
        </Typography>
        <Typography sx={{ mt: 2, mb: 2 }}>
          Al hacer clic en Crear, se importará la información de la empresa seleccionada. Luego
          podrás validar y centralizar esos datos.
        </Typography>
        {periods !== undefined && periods !== null && !!formattedOptions && (
          <Box sx={{ mt: 1 }}>
            <Autocomplete
              id="combo-box-demo"
              options={formattedOptions}
              defaultValue={formattedOptions[0]}
              getOptionLabel={(option) => `${option.label}`}
              noOptionsText="No hay periodos disponibles"
              onChange={handlePeriodChange}
              renderInput={(params) => <TextField {...params} label="Periodo" />}
            />
          </Box>
        )}

        <Box sx={{ mt: 1 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="type-select-label">Tipo de centralización</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={selectedType}
              onChange={handleTypeChange}
              label="Tipo de centralización">
              <MenuItem value={CENTRALIZATION}>Remuneraciones</MenuItem>
              <MenuItem value={TERMINATION}>Finiquitos</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              id="combo-box-demo"
              options={companies}
              getOptionLabel={(option) => `${option.name} RUT: ${option.rut}`}
              noOptionsText="No hay empresas disponibles"
              onChange={handleCompanyChange}
              renderOption={(props, option) => (
                <li {...props}>
                  {`${option.name}`}
                  <span style={{ color: '#9E9E9E', fontWeight: 'lighter' }}>
                    {' '}
                    &nbsp; RUT: {option.rut}
                  </span>
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Empresa" />}
            />
          </FormControl>
        </Box>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={onClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              onClick={handleCreate}
              disabled={!(!!selectedCompany && !!selectedType)}>
              Crear
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default CentralizationCreationModal;
