import { createAction } from '@reduxjs/toolkit';
import CentralizationTypes from './centralizations.types';

export const createCentralization = createAction(
  CentralizationTypes.CREATE_CENTRALIZATION,
  (payload) => ({ payload }),
);

export const createCentralizationSuccess = createAction(
  CentralizationTypes.CREATE_CENTRALIZATION_SUCCESS,
  (payload) => ({ payload }),
);

export const createCentralizationFail = createAction(
  CentralizationTypes.CREATE_CENTRALIZATION_FAIL,
  (payload) => ({ payload }),
);

export const checkCentralizationStatus = createAction(
  CentralizationTypes.CHECK_CENTRALIZATION_STATUS,
  (payload) => ({ payload }),
);

export const fetchCentralizations = createAction(
  CentralizationTypes.FETCH_CENTRALIZATIONS,
  (payload) => ({ payload }),
);

export const changePage = createAction(CentralizationTypes.CHANGE_PAGE, (payload) => ({
  payload,
}));

export const changeSize = createAction(CentralizationTypes.CHANGE_SIZE, (payload) => ({
  payload,
}));

export const fetchCentralizationsSuccess = createAction(
  CentralizationTypes.FETCH_CENTRALIZATIONS_SUCCESS,
  (payload) => ({ payload }),
);
export const fetchCentralizationsFail = createAction(
  CentralizationTypes.FETCH_CENTRALIZATIONS_FAIL,
);

export const openCreateCentralizationModal = createAction(
  CentralizationTypes.OPEN_CREATE_CENTRALIZATION_MODAL,
);

export const closeCreateCentralizationModal = createAction(
  CentralizationTypes.CLOSE_CREATE_CENTRALIZATION_MODAL,
);

export const centralizationType = createAction(
  CentralizationTypes.CENTRALIZATION_TYPE,
  (payload) => ({ payload }),
);

export const setSelectCompany = createAction(CentralizationTypes.SET_SELECT_COMPANY, (company) => ({
  payload: company,
}));

export const showError = createAction(CentralizationTypes.SHOW_ERROR, (payload) => ({ payload }));

export const hideError = createAction(CentralizationTypes.HIDE_ERROR);

export const getAllPeriods = createAction(CentralizationTypes.GET_LAST_PERIOD);

export const setSelectPeriod = createAction(CentralizationTypes.SET_SELECT_PERIOD, (period) => ({
  payload: period,
}));

export const getAllPeriodsSuccess = createAction(
  CentralizationTypes.GET_LAST_PERIOD_SUCCESS,
  (payload) => ({ payload }),
);

export const getAllPeriodsFail = createAction(
  CentralizationTypes.GET_LAST_PERIOD_FAIL,
  (payload) => ({
    payload,
  }),
);

export const checkCentralizationExistence = createAction(
  CentralizationTypes.CHECK_CENTRALIZATION_EXISTENCE,
  (payload) => ({ payload }),
);

export const checkCentralizationExistenceSuccess = createAction(
  CentralizationTypes.CHECK_CENTRALIZATION_EXISTENCE_SUCCESS,
  (payload) => ({ payload }),
);

export const checkCentralizationExistenceFail = createAction(
  CentralizationTypes.CHECK_CENTRALIZATION_EXISTENCE_FAIL,
  (payload) => ({
    payload,
  }),
);

export const resetCentralizationExistence = createAction(
  CentralizationTypes.RESET_CENTRALIZATION_EXISTENCE,
);

const CentralizationActions = {
  createCentralization,
  createCentralizationSuccess,
  createCentralizationFail,
  fetchCentralizations,
  fetchCentralizationsSuccess,
  fetchCentralizationsFail,
  openCreateCentralizationModal,
  closeCreateCentralizationModal,
  centralizationType,
  setSelectCompany,
  changePage,
  changeSize,
  checkCentralizationStatus,
  showError,
  hideError,
  getAllPeriods,
  getAllPeriodsSuccess,
  getAllPeriodsFail,
  setSelectPeriod,
  checkCentralizationExistence,
  checkCentralizationExistenceSuccess,
  checkCentralizationExistenceFail,
  resetCentralizationExistence,
};

export default CentralizationActions;
