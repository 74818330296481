/* eslint-disable complexity */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { changePage, changeSize, fetchCentralizations } from '../centralizations.actions';

function CentralizationsTable(props) {
  const dispatch = useDispatch();

  const { centralizations, pagination } = props;
  const { page, size, count } = pagination;

  const handleChangePage = (event, newPage) => {
    dispatch(changePage(newPage));
    dispatch(fetchCentralizations({ page: newPage, size: pagination.size }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeSize(parseInt(event.target.value, 10)));
    dispatch(changePage(0));
    dispatch(fetchCentralizations({ page: 0, size: parseInt(event.target.value, 10) }));
  };

  const headerStyle = {
    '& th': {
      fontWeight: 'bold',
      backgroundColor: '#F0F6FE',
    },
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="centralization table">
          <TableHead>
            <TableRow sx={headerStyle}>
              <TableCell>Código EBCO Connect</TableCell>
              <TableCell>Código transacción SAP</TableCell>
              <TableCell>Periodo</TableCell>
              <TableCell>Empresa</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>ID usuario</TableCell>
              <TableCell>Detalle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {centralizations && centralizations.length > 0 ? (
              centralizations.map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell>{entry.id}</TableCell>
                  <TableCell>{entry.sapTransactionCode}</TableCell>
                  <TableCell>{moment(entry.period).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{entry.company}</TableCell>
                  <TableCell>{moment(entry.createdAt).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{entry.type}</TableCell>
                  <TableCell>{entry.userId}</TableCell>
                  <TableCell>
                    <Link
                      to={`/centralizations/${entry.id}`}
                      style={{ textDecoration: 'none', color: '#2F80ED' }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}>
                      Ver
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <Typography variant="body1" gutterBottom>
                    No se encontraron centralizaciones
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100, { label: 'Todos', value: count }]}
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count: countParam }) => `${from}–${to} de ${countParam}`}
      />
    </Paper>
  );
}

export default CentralizationsTable;
