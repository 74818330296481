import { takeLatest, spawn, put } from 'redux-saga/effects';

/* Project */
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import api from 'utils/api';
import {
  login,
  loginSuccess,
  loginFail,
  googleLogin,
  googleLoginSuccess,
  googleLoginFail,
  logout,
  logoutSuccess,
  samlLogin,
  samlLoginSuccess,
} from './login.actions';

function* loginUser() {
  yield takeLatest(login, function* loginUserApi(action) {
    const body = JSON.stringify(action.payload);
    const response = yield api('api/v1/auth/login', { method: 'post', body });

    if (!response.error) {
      localStorage.setItem('user', JSON.stringify({ token: response.token }));

      yield put(loginSuccess({ user: response.user, userRole: response.userRole }));
    } else {
      yield put(loginFail());
      defaultErrorToast({ message: response.message });
    }
  });
}

function* loginUserByGoogle() {
  yield takeLatest(googleLogin, function* fetchGoogleLogin(action) {
    const body = JSON.stringify(action.payload);

    const response = yield api('api/v1/auth/google', { method: 'post', body });

    if (!response.error) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          token: response.token,
        }),
      );

      yield put(
        googleLoginSuccess({
          user: response.user,
        }),
      );
    } else {
      yield put(googleLoginFail());
      defaultErrorToast({ message: response.message });
    }
  });
}

function* logoutUser() {
  yield takeLatest(logout, function* logoutLocalUser() {
    localStorage.clear();
    yield put(logoutSuccess());
    window.location.replace('https://escritorio.ebco.cl');
  });
}

function* loginUserSaml() {
  yield takeLatest(samlLogin, function* samlLoginFunc(action) {
    const body = JSON.stringify(action.payload);
    const response = yield api('api/v1/auth/saml/login', { method: 'post', body });

    if (!response.error) {
      localStorage.setItem('user', JSON.stringify({ token: response.token }));

      yield put(loginSuccess({ user: response.user }));
      yield put(samlLoginSuccess(response));
    } else {
      yield put(loginFail());
      defaultErrorToast({ message: response.message });
    }
  });
}

export default function* LoginSaga() {
  yield spawn(loginUser);
  yield spawn(logoutUser);
  yield spawn(loginUserByGoogle);
  yield spawn(loginUserSaml);
}
