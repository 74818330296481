import { takeLatest, spawn, put } from 'redux-saga/effects';

/* Project */
import apiRequest from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import {
  fetchData,
  fetchDataFail,
  fetchDataSuccess,
  assignBuildingCodeUsers,
  assignBuildingCodeUsersSuccess,
  assignBuildingCodeUsersFail,
} from './buildingCodes.actions';

function* fetchBuildingCodes() {
  yield takeLatest(fetchData, function* fetchDataFunc(action) {
    const urlParams = new URLSearchParams({
      page: action.payload.page,
      pageSize: action.payload.size,
      name: action.payload.name,
      code: action.payload.code,
    });

    const response = yield apiRequest(`api/v1/building-code?${urlParams}`, {
      method: 'get',
    });

    if (!response.error) {
      yield put(fetchDataSuccess(response));
    } else {
      yield put(fetchDataFail());
      defaultErrorToast({ message: 'Falló la carga de codigos de obra.' });
    }
  });
}

function* assignBuildingCode() {
  yield takeLatest(assignBuildingCodeUsers, function* fetchDataFunc(action) {
    const { buildCodeId, userIds } = action.payload;
    const body = JSON.stringify({ buildCodeId, userIds });

    const response = yield apiRequest(`api/v1/building-code`, {
      method: 'post',
      body,
    });

    if (!response.error) {
      yield put(assignBuildingCodeUsersSuccess(response));
    } else {
      yield put(assignBuildingCodeUsersFail());
      defaultErrorToast({ message: 'Falló la asignacion de usuarios.' });
    }
  });
}

export default function* BuildingCodesSaga() {
  yield spawn(fetchBuildingCodes);
  yield spawn(assignBuildingCode);
}
