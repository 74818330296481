import React from 'react';

/* Material UI */
import { Container, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { LoginActions } from 'content/features/authentication';
import { Sidebar } from '../../shared';

function Layout(props) {
  const { children, element } = props;
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(LoginActions.logout());
  };
  return (
    <Container maxWidth={false} sx={{ paddingTop: '85px' }}>
      <Grid container direction="row" justifyContent="center">
        <Grid xs={12} sm={12} lg={12} xl={11}>
          <Sidebar handleLogout={handleLogout} />
          {element || children}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Layout;
