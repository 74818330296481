/* eslint-disable complexity */
import { Grid, Typography } from '@mui/material';
import ComponentLayout from 'content/containers/Layout/ComponentLayout';
import Filters from 'content/features/searchLiquidations/components/Filters';
import Navegation from 'content/features/searchLiquidations/components/Navegation';
import ResultsTable from 'content/features/searchLiquidations/components/ResultsTable';
import { getLiquidations } from 'content/features/searchLiquidations/searchLiquidations.actions';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// this file isn't used
function Liquidations() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { objects } = useSelector((state) => state.app);
  const { controls } = useSelector((state) => state.liquidations);
  const {
    pagination,
    controls: requestControls,
    filters,
    loaders,
    sorting,
    resultIds,
  } = useSelector((state) => state.searchLiquidations);

  const { liquidations, items } = objects;
  const liquidationsData = useMemo(
    () => resultIds.map((id) => liquidations[id]),
    [liquidations, resultIds],
  );
  const itemsData = useMemo(() => Object.values(items), [items]);

  const fetchLiquidations = () => {
    dispatch(getLiquidations());
  };

  const sortLiquidations = () => {
    dispatch(getLiquidations());
  };

  useEffect(() => {
    if (requestControls.buildCode === '' && pagination.count === 0) {
      navigate('/simulador');
    }
  }, [requestControls.buildCode, pagination.count]);

  return (
    <ComponentLayout>
      <Grid container>
        <Grid item xs={12}>
          <Navegation />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Planilla de simulación
          </Typography>
        </Grid>
        <Filters filters={filters} fetchLiquidations={fetchLiquidations} />
        <ResultsTable
          liquidations={liquidationsData}
          items={itemsData}
          controls={controls}
          pagination={pagination}
          fetchLiquidations={fetchLiquidations}
          sortLiquidations={sortLiquidations}
          loaders={loaders}
          sorting={sorting}
          searchControls={requestControls}
        />
      </Grid>
    </ComponentLayout>
  );
}

export default Liquidations;
