/* eslint-disable max-depth */
/* eslint-disable complexity */
import { takeLatest, put, spawn, delay } from 'redux-saga/effects';
import apiRequest from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import {
  createCentralization,
  createCentralizationSuccess,
  createCentralizationFail,
  checkCentralizationStatus,
  fetchCentralizationsSuccess,
  fetchCentralizationsFail,
  fetchCentralizations,
  getAllPeriods,
  getAllPeriodsSuccess,
  getAllPeriodsFail,
  checkCentralizationExistence,
  checkCentralizationExistenceSuccess,
} from './centralizations.actions';

function* handleCreateCentralization() {
  yield takeLatest(createCentralization, function* createCentralizationFunc(action) {
    try {
      const { period, type, company } = action.payload;

      const body = JSON.stringify({
        period,
        type,
        company,
      });
      console.log('body', body);

      const response = yield apiRequest('api/v1/accountability-records', { method: 'post', body });
      console.log('response en saga', response);

      if (!response.error) {
        yield put(checkCentralizationStatus({ recordId: response.data.id }));
      } else {
        yield put(createCentralizationFail());
      }
    } catch (error) {
      console.log('error', error);
      yield put(createCentralizationFail());
    }
  });
}

function* getCentralizationResponse() {
  yield takeLatest(checkCentralizationStatus, function* fetchDataFunc(action) {
    const { recordId } = action.payload;
    let isOkay = false;
    let errorMessage = '';
    try {
      let response;
      do {
        response = yield apiRequest(`api/v1/accountability-records/${recordId}/status`, {
          method: 'get',
        });

        if (response.status === 'completed') {
          isOkay = true;
          yield put(createCentralizationSuccess(recordId));
          return;
        }
        if (response.status === 'error') {
          errorMessage = response.error;
          isOkay = true;
          yield apiRequest(`api/v1/accountability-records/${recordId}`, {
            method: 'delete',
          });
          throw new Error(response.errorDetails || 'Error desconocido');
        } else {
          yield delay(3000);
        }
      } while (response.status !== 'completed' && !isOkay);
    } catch (error) {
      yield put(createCentralizationFail(errorMessage || error.message));
    }
  });
}

function* handleFetchCentralizations() {
  yield takeLatest(fetchCentralizations, function* fetchDataFunc(action) {
    const urlParams = new URLSearchParams({
      page: action.payload.page,
      pageSize: action.payload.size,
    });

    const response = yield apiRequest(`api/v1/accountability-records?${urlParams}`, {
      method: 'get',
    });

    if (!response.error) {
      yield put(
        fetchCentralizationsSuccess({
          list: response.data,
          pagination: {
            count: response.count,
            page: response.page,
            pageSize: response.pageSize,
          },
        }),
      );
    } else {
      yield put(fetchCentralizationsFail());
    }
  });
}

function* handleGetAllPeriods() {
  yield takeLatest(getAllPeriods, function* fetchDataFunc() {
    const response = yield apiRequest('api/v1/accountability-records/all-periods', {
      method: 'get',
    });

    if (!response.error) {
      yield put(getAllPeriodsSuccess(response));
    } else {
      yield put(getAllPeriodsFail());
    }
  });
}

function* handleCheckCentralizationExistence() {
  yield takeLatest(checkCentralizationExistence, function* fetchDataFunc(action) {
    const { company, period, type } = action.payload;
    const urlParams = new URLSearchParams({
      bukPeriodId: period.id,
      companyId: company.id,
      type,
    });

    const response = yield apiRequest(
      `api/v1/accountability-records/centralization-exists?${urlParams}`,
      {
        method: 'get',
      },
    );
    if (response.error) {
      defaultErrorToast({ message: response.message });
      return;
    }
    if (response.exists === true) {
      yield put(checkCentralizationExistenceSuccess(response.data));
    } else if (response.exists === false) {
      yield put(createCentralization({ period, type, company }));
    } else defaultErrorToast({ message: 'Ocurrio un error al revisar la centralización!' });
  });
}

export default function* centralizationSaga() {
  yield spawn(handleCreateCentralization);
  yield spawn(handleFetchCentralizations);
  yield spawn(getCentralizationResponse);
  yield spawn(handleGetAllPeriods);
  yield spawn(handleCheckCentralizationExistence);
}
