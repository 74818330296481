/* eslint-disable complexity */
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { setSorting } from 'content/features/searchLiquidations/searchLiquidations.actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

function TableHeader() {
  const dispatch = useDispatch();
  const {
    sorting: { key, direction },
    headers,
  } = useSelector((state) => state.searchLiquidations);

  const handleSorting = (header) => {
    dispatch(setSorting(header));
  };

  const directionValidation = direction ? 'asc' : 'desc';
  return (
    <TableHead>
      <TableRow>
        {headers.map((header) => (
          <TableCell
            align={header.align || 'right'}
            sx={{ fontWeight: 'bold', minWidth: header.width }}>
            {header.isSortable ? (
              <TableSortLabel
                active={key === header.code}
                direction={key === header.code ? directionValidation : 'desc'}
                onClick={() => handleSorting(header)}>
                {header.name}
              </TableSortLabel>
            ) : (
              header.name
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
