// eslint-disable-next-line complexity
import { takeLatest, put, spawn } from 'redux-saga/effects';
import apiRequest from 'utils/api';

import {
  fetchCentralizationDetails,
  fetchCentralizationDetailsSuccess,
  fetchCentralizationDetailsFail,
  validateDataFail,
  validateData,
  centralizeData,
  centralizeDataFail,
  showNotification,
  getPeriod,
  getPeriodSuccess,
  getPeriodFail,
  getCompanySuccess,
  getCompanyFail,
  getCompany,
  centralizeDataSuccess,
  deleteCentralization,
} from './centralizationDetails.actions';

function* handleFetchCentralizationDetails() {
  yield takeLatest(fetchCentralizationDetails, function* fetchDataFunc(action) {
    const urlParams = new URLSearchParams({
      page: action.payload.page,
      pageSize: action.payload.size,
    });

    const response = yield apiRequest(
      `api/v1/accountability-records/${action.payload.accountabilityRecordId}/lines?${urlParams}`,
      {
        method: 'get',
      },
    );

    if (!response.error) {
      yield put(
        fetchCentralizationDetailsSuccess({
          list: response.list,
          pagination: {
            count: response.count,
            page: response.page,
            pageSize: response.pageSize,
          },
          record: response.record,
          totalBudget: response.totalBudget,
        }),
      );
    } else {
      yield put(fetchCentralizationDetailsFail(response.error));
    }
  });
}

function* handleValidateData() {
  yield takeLatest(validateData, function* validateDataFunc(action) {
    const { recordId } = action.payload;
    const urlParams = new URLSearchParams({
      page: action.payload.page,
      pageSize: action.payload.size,
    });

    const validationResponse = yield apiRequest(
      `api/v1/accountability-records/${recordId}/validate?${urlParams}`,
      {
        method: 'post',
      },
    );

    if (!validationResponse.error) {
      yield put(
        fetchCentralizationDetailsSuccess({
          list: validationResponse.list,
          pagination: {
            count: validationResponse.count,
            page: validationResponse.page,
            pageSize: validationResponse.pageSize,
          },
          record: validationResponse.record,
          totalBudget: validationResponse.totalBudget,
        }),
      );
    } else {
      yield put(validateDataFail(validationResponse.error));
    }
  });
}

function* handleCentralizeRecord() {
  yield takeLatest(centralizeData, function* centralizeRecordFunc(action) {
    const { recordId } = action.payload;
    const urlParams = new URLSearchParams({
      page: action.payload.page,
      pageSize: action.payload.size,
    });

    const centralizationResponse = yield apiRequest(
      `api/v1/accountability-records/${recordId}/centralize?${urlParams}`,
      {
        method: 'post',
      },
    );

    if (!centralizationResponse.error) {
      yield put(
        centralizeDataSuccess({
          record: centralizationResponse,
        }),
      );
      yield put(
        showNotification({ message: 'Datos centralizados correctamente', severity: 'success' }),
      );
    } else {
      yield put(centralizeDataFail(centralizationResponse.error));
      yield put(showNotification({ message: centralizationResponse.error, severity: 'error' }));
    }
  });
}

function* handleGetCompany() {
  yield takeLatest(getCompany, function* getCompanyFunc(action) {
    const { companyId } = action.payload;
    const response = yield apiRequest(`api/v1/accountability-records/companies/${companyId}`, {
      method: 'get',
    });

    if (!response.error) {
      yield put(getCompanySuccess(response));
    } else {
      yield put(getCompanyFail(response.error));
    }
  });
}

function* handleGetPeriod() {
  yield takeLatest(getPeriod, function* getPeriodFunc(action) {
    const { periodId } = action.payload;
    const response = yield apiRequest(`api/v1/accountability-records/periods/${periodId}`, {
      method: 'get',
    });

    if (!response.error) {
      yield put(getPeriodSuccess(response));
    } else {
      yield put(getPeriodFail(response.error));
    }
  });
}

function* handleDeleteCentralization() {
  yield takeLatest(deleteCentralization, function* deleteCentralizationFun(action) {
    const { id, goToCentralizations } = action.payload;
    const response = yield apiRequest(`api/v1/accountability-records/${id}`, {
      method: 'delete',
    });

    if (!response.error) {
      yield put(
        showNotification({
          message: 'Centralización eliminada correctamente',
          severity: 'success',
        }),
      );
      // eslint-disable-next-line no-promise-executor-return
      const delay = (ms = 2000) => new Promise((resolve) => setTimeout(resolve, ms));
      yield delay();
      goToCentralizations();
    } else {
      yield put(showNotification({ message: response.message, severity: 'error' }));
    }
  });
}

export default function* centralizationDetailsSaga() {
  yield spawn(handleFetchCentralizationDetails);
  yield spawn(handleValidateData);
  yield spawn(handleCentralizeRecord);
  yield spawn(handleGetCompany);
  yield spawn(handleGetPeriod);
  yield spawn(handleDeleteCentralization);
}
