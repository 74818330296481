/* Reducers */
import { CounterReducer } from 'content/features/counter';
import AppReducer from 'content/app.reducer';
import { LoginReducer, RecoveryReducer, RegisterReducer } from 'content/features/authentication';
import { UserTasksReducer } from 'content/features/tasks';
import { BuildingCodesReducer } from 'content/features/BuildingCodeMaintainer';
import { SearchLiquidationsReducer } from 'content/features/searchLiquidations';
import { LiquidationsReducer } from 'content/features/liquidations';
import { CentralizationReducer } from 'content/features/centralizations';
import { CompaniesReducer } from 'content/features/companies';
import { centralizationDetailsReducer } from 'content/features/centralizationDetails';

const MainReducer = {
  app: AppReducer,
  login: LoginReducer,
  register: RegisterReducer,
  counter: CounterReducer,
  recovery: RecoveryReducer,
  userTasks: UserTasksReducer,
  buildingCodes: BuildingCodesReducer,
  searchLiquidations: SearchLiquidationsReducer,
  liquidations: LiquidationsReducer,
  centralizations: CentralizationReducer,
  companies: CompaniesReducer,
  centralizationDetails: centralizationDetailsReducer,
};

export default MainReducer;
