import styled from '@emotion/styled';
import { Skeleton, TableCell, TableRow, tableCellClasses } from '@mui/material';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: '63.75px!important',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function ResultsTableSkeleton() {
  return (
    <>
      {[...Array(10)].map(() => (
        <StyledTableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
          <StyledTableCell align="left">
            <Skeleton animation="wave" width="25px" height="20px" />
          </StyledTableCell>
          <StyledTableCell align="left">
            <Skeleton animation="wave" width="300px" height="20px" />
          </StyledTableCell>
          <StyledTableCell align="left">
            <Skeleton animation="wave" width="100px" height="20px" />
          </StyledTableCell>
          <StyledTableCell align="left">
            <Skeleton animation="wave" width="100px" height="20px" />
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </>
  );
}

export default ResultsTableSkeleton;
