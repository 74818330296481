/* Material UI */
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import QuizIcon from '@mui/icons-material/Quiz';

/* Router */
import Counter from 'content/pages/Counter/Counter';
// import Home from 'content/pages/Home/Home';
import Login from 'content/pages/Login/Login';
import RegisterUser from 'content/pages/Register/Register';
import RecoveryPassword from 'content/pages/Recovery/RecoveryPassword';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { MediationOutlined } from '@mui/icons-material';
import BuildingCodeMaintainer from 'content/pages/BuildingCodeMaintainer/BuildingCodeMaintainer';
import SearchLiquidations from 'content/pages/SearchLiquidations/SearchLiquidations';
import Liquidations from 'content/pages/Liquidations/Liquidations';
import Centralizations from 'content/pages/Centralizations/Centralizations';
import Centralization from 'content/pages/CentralizationDetails/Centralization';
import Home from 'content/pages/Home/Home';

const Paths = {
  publics: [
    {
      path: '/recovery/*',
      component: RecoveryPassword,
      useLayout: false,
      Icon: null,
      isAuth: true,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      useLayout: false,
      Icon: AccountCircleIcon,
      isAuth: true,
    },
    {
      path: '/register',
      component: RegisterUser,
      useLayout: false,
      Icon: null,
      isAuth: true,
    },
  ],
  shared: [],
  protecteds: [
    {
      path: '/',
      name: 'Inicio',
      component: Home,
      useLayout: true,
      Icon: HomeIcon,
      hide: true,
      permission: 'default',
    },
    {
      path: '/simulador',
      name: 'Simulador',
      component: SearchLiquidations,
      useLayout: true,
      Icon: HomeOutlinedIcon,
      hide: false,
      permission: 'simulation',
    },
    // {
    //   path: '/simulaciones',
    //   name: 'Simulaciones',
    //   component: Home,
    //   useLayout: true,
    //   Icon: AccountCircleOutlinedIcon,
    //   hide: false,
    // },
    {
      path: '/codigo-obra',
      name: 'Mantenedores',
      component: BuildingCodeMaintainer,
      useLayout: true,
      Icon: AccountCircleOutlinedIcon,
      hide: false,
      permission: false,
    },
    {
      path: '/liquidaciones',
      name: 'Liquidaciones',
      component: Liquidations,
      useLayout: true,
      Icon: AccountCircleOutlinedIcon,
      hide: true,
      permission: 'simulation',
    },
    {
      path: '/centralizations',
      name: 'Centralización',
      component: Centralizations,
      useLayout: true,
      Icon: MediationOutlined,
      hide: false,
      permission: 'centralization',
    },
    {
      path: '/centralizations/:id',
      name: 'Centralización Detalle',
      component: Centralization,
      useLayout: true,
      Icon: AccountCircleOutlinedIcon,
      hide: true,
      permission: 'centralization',
    },
  ],
  independients: [
    {
      path: '/faq',
      name: 'FAQ',
      component: Counter,
      Icon: QuizIcon,
    },
  ],
};

export default Paths;
