import { createReducer } from '@reduxjs/toolkit';

import {
  disableErrors,
  googleLogin,
  googleLoginSuccess,
  googleLoginFail,
  login,
  loginSuccess,
  loginFail,
  controlsChanged,
  samlLogin,
  samlLoginSuccess,
} from './login.actions';

const initialState = {
  loading: false,
  message: '',
  error: false,
  controls: {
    email: '',
    password: '',
  },
};

const LoginReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(disableErrors, (state) => {
      state.error = false;
    })
    .addCase(googleLogin, (state) => {
      state.error = false;
      state.loading = true;
    })
    .addCase(googleLoginSuccess, (state) => {
      state.error = false;
      state.loading = false;
    })
    .addCase(googleLoginFail, (state) => {
      state.error = true;
      state.loading = false;
    })
    .addCase(login, (state) => {
      state.error = false;
      state.loading = true;
    })
    .addCase(loginSuccess, (state) => {
      state.error = false;
      state.loading = false;
      state.controls = {
        email: '',
        password: '',
      };
    })
    .addCase(loginFail, (state) => {
      state.error = true;
      state.loading = false;
    })
    .addCase(controlsChanged, (state, action) => {
      state.controls = {
        ...state.controls,
        ...action.payload,
      };
    })
    .addCase(samlLogin, (state) => {
      state.loading = true;
    })
    .addCase(samlLoginSuccess, (state) => {
      state.loading = false;
    });
});

export default LoginReducer;
