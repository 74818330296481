/* eslint-disable complexity */
import styled from '@emotion/styled';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import React from 'react';
import { useDispatch } from 'react-redux';
import { changePage, changeSize } from '../buildingCodes.actions';
import ResultsTableSkeleton from './ResultsTableSkeleton';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function ResultsTable(props) {
  const dispatch = useDispatch();

  const { pagination, data, loaders, handleToggleDialog } = props;
  const { count, page, size } = pagination;
  const { page: pageLoaders } = loaders;

  const handleChangePage = (event, newPage) => {
    dispatch(changePage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeSize(parseInt(event.target.value, 10)));
    dispatch(changePage(0));
  };

  const results =
    count > 0 ? (
      Object.values(data).map((row) => (
        <StyledTableRow
          key={row.id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          hover>
          <StyledTableCell align="left">{row.id}</StyledTableCell>
          <StyledTableCell align="left">{row.name}</StyledTableCell>
          <StyledTableCell align="left">{row.code}</StyledTableCell>
          <StyledTableCell align="left">
            <Button size="small" onClick={() => handleToggleDialog(row)}>
              Asignar Usuario
            </Button>
          </StyledTableCell>
        </StyledTableRow>
      ))
    ) : (
      <StyledTableCell align="center" colSpan={4}>
        Sin resultados.
      </StyledTableCell>
    );

  return (
    <Grid container sx={{ mt: 3 }}>
      <TableContainer component={Paper} elevation={0} sx={{ border: '1px solid #e0e0e0' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Id</TableCell>
              <TableCell align="left">Nombre</TableCell>
              <TableCell align="left">Código</TableCell>
              <TableCell align="left">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{!pageLoaders ? results : <ResultsTableSkeleton />}</TableBody>
        </Table>
      </TableContainer>
      <Grid container direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={size}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página:"
        />
      </Grid>
    </Grid>
  );
}

export default ResultsTable;
