/* eslint-disable complexity */
/* eslint-disable max-depth */

import { takeLatest, spawn, put, select } from 'redux-saga/effects';

/* Project */
import apiRequest, { getDocument, post } from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import {
  getLiquidations,
  getLiquidationsSuccess,
  getLiquidationsFail,
  getLiquidationsFileSuccess,
  getLiquidationsFileFail,
  getLiquidationsFile,
  simulateLiquidationFail,
  simulateLiquidation,
  simulateLiquidationSuccess,
  checkSimulationResponse,
  checkSimulationResponseFail,
  setSimulationStatus,
  checkSimulationResponseSuccess,
  checkLiquidationResponse,
} from './searchLiquidations.actions';

const IN_PROGRESS = 'in-progress';
const COMPLETED = 'completed';
const FAILED = 'failed';

function* fetchLiquidations() {
  yield takeLatest(getLiquidations, function* fetchDataFunc(action) {
    const { firstCall } = action.payload || {};
    const { controls, pagination, filters } = yield select((state) => state.searchLiquidations);
    const urlParams = new URLSearchParams({
      ...filters,
      buildCode: controls.buildCode[0].code,
      page: pagination.page,
      pageSize: pagination.size,
      firstCall,
    });

    const response = yield apiRequest(`api/v1/liquidation?${urlParams}`, {
      method: 'get',
    });

    if (!response.error) {
      if (response.status === 'in-queue') {
        yield put(checkLiquidationResponse({ timestamp: response.timestamp }));
      } else {
        yield put(getLiquidationsSuccess({ ...response, objects: response.objects }));
      }
    } else {
      yield put(getLiquidationsFail());
      defaultErrorToast({ message: response.message });
    }
  });
}

function* getLiquidationFile() {
  yield takeLatest(getLiquidationsFile, function* fetchDataFunc(action) {
    const urlParams = new URLSearchParams(action.payload);

    const response = yield getDocument(`api/v1/liquidation/get-file?${urlParams}`, 'reporte.xlsx');

    if (!response.error) {
      yield put(getLiquidationsFileSuccess(response));
    } else {
      yield put(getLiquidationsFileFail());
      defaultErrorToast({ message: response.message });
    }
  });
}

function* simulateLiquidations() {
  yield takeLatest(simulateLiquidation, function* fetchDataFunc(action) {
    const { controls, pagination, sorting, filters, period } = yield select(
      (state) => state.searchLiquidations,
    );

    const { saveSimulation } = action.payload;
    const socketId = localStorage.getItem('socketId');
    const timestamp = Date.now();
    const body = {
      items: controls.simulationItems,
      buildCode: controls.buildCode[0].code,
      filters,
      pagination: {
        pageParam: pagination.page,
        pageSizeParam: pagination.size,
        key: sorting.key,
        direction: sorting.direction,
      },
      socketId,
      saveSimulation: !!saveSimulation,
      timestamp,
      period: {
        id: period.id,
        startDate: period.startDate,
        endDate: period.endDate,
        bukPeriodId: period.bukPeriodId,
      },
    };

    const response = yield post(`api/v1/simulationV2/simulate-liquidation`, body);

    if (response.error) {
      yield put(simulateLiquidationFail());
      defaultErrorToast({ message: response.message });
    } else {
      yield put(simulateLiquidationSuccess(response));
      yield put(
        checkSimulationResponse({
          timestamp,
          saveSimulation: body.saveSimulation,
        }),
      );
    }
  });
}

function* getSimulationResponse() {
  yield takeLatest(checkSimulationResponse, function* fetchDataFunc(action) {
    const { timestamp, saveSimulation } = action.payload;

    let isInProgress = true;
    let hasErrors = false;

    const urlParams = new URLSearchParams({
      timestamp,
    });
    // delay for 2 seconds
    // eslint-disable-next-line no-promise-executor-return
    const delay = (ms = 2000) => new Promise((resolve) => setTimeout(resolve, ms));
    yield delay();
    let maxRetryError = 3;
    do {
      let response;
      try {
        response = yield apiRequest(`api/v1/simulation/check-simulation-response?${urlParams}`, {
          method: 'get',
        });
      } catch (error) {
        maxRetryError -= 1;
        if (maxRetryError === 0) {
          isInProgress = false;
          yield put(checkSimulationResponseFail());
          defaultErrorToast({ message: 'Ocurrio un error!' });
          break;
        }
        console.log('error', error);
      }

      if (response.error) {
        hasErrors = response.error;
        yield delay();
        // eslint-disable-next-line no-continue
        continue;
      }

      if (response.status === IN_PROGRESS) {
        yield put(setSimulationStatus({ statusMsg: response.message }));
        yield delay();
        // eslint-disable-next-line no-continue
        continue;
      }
      if (response.status === FAILED) {
        isInProgress = false;
        yield put(setSimulationStatus({ statusMsg: response.message }));
        yield put(checkSimulationResponseFail());
        defaultErrorToast({ message: response.statusMsg });
        // eslint-disable-next-line no-continue
        continue;
      }

      if (response.status === COMPLETED) {
        isInProgress = false;
        yield put(setSimulationStatus({ statusMsg: response.message, saveSimulation }));
        yield put(checkSimulationResponseSuccess(response.data));
        defaultSuccessToast({ message: response.message });
      } else {
        yield delay();
      }
    } while (isInProgress);

    if (hasErrors) {
      yield put(checkSimulationResponseFail());
    }
  });
}

function* getLiquidationResponse() {
  yield takeLatest(checkLiquidationResponse, function* fetchDataFunc(action) {
    const { timestamp } = action.payload;

    let isInProgress = true;

    const urlParams = new URLSearchParams({
      timestamp,
    });
    // delay for 2 seconds
    // eslint-disable-next-line no-promise-executor-return
    const delay = (ms = 2000) => new Promise((resolve) => setTimeout(resolve, ms));
    yield delay();
    let maxRetryError = 3;
    do {
      let response;
      try {
        response = yield apiRequest(`api/v1/liquidation/check-liquidation-response?${urlParams}`, {
          method: 'get',
        });
      } catch (error) {
        maxRetryError -= 1;
        if (maxRetryError === 0) {
          isInProgress = false;
          defaultErrorToast({ message: 'Ocurrio un error!' });
          break;
        }
        console.log('error', error);
      }

      if (response.error) {
        isInProgress = false;
        defaultErrorToast({ message: 'Error al intentar buscar la obra' });
        // eslint-disable-next-line no-continue
        continue;
      }

      if (response.status === IN_PROGRESS) {
        yield delay();
        // eslint-disable-next-line no-continue
        continue;
      }
      if (response.status === FAILED) {
        isInProgress = false;
        defaultErrorToast({ message: 'Error al intentar buscar la obra' });
        // eslint-disable-next-line no-continue
        continue;
      }

      if (response.status === COMPLETED) {
        isInProgress = false;
        const result = response.response || {};
        yield put(getLiquidationsSuccess({ ...result, objects: result.objects }));
      } else {
        yield delay();
      }
    } while (isInProgress);
  });
}

export default function* SearchLiquidationsSaga() {
  yield spawn(fetchLiquidations);
  yield spawn(getLiquidationFile);
  yield spawn(simulateLiquidations);
  yield spawn(getSimulationResponse);
  yield spawn(getLiquidationResponse);
}
