import { createReducer } from '@reduxjs/toolkit';
import { fetchCompanies, fetchCompaniesSuccess, fetchCompaniesFail } from './companies.actions';

const initialState = {
  isLoading: false,
  companies: [],
  error: null,
};

const companiesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchCompanies, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchCompaniesSuccess, (state, action) => {
      state.isLoading = false;
      state.companies = action.payload;
      state.error = null;
    })
    .addCase(fetchCompaniesFail, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
});

export default companiesReducer;
