const BASE = '[CENTRALIZATION_DETAILS]';

const CentralizationDetailsTypes = {
  FETCH_CENTRALIZATION_DETAILS: `${BASE} FETCH_CENTRALIZATION_DETAILS`,
  FETCH_CENTRALIZATION_DETAILS_SUCCESS: `${BASE} FETCH_CENTRALIZATION_DETAILS_SUCCESS`,
  FETCH_CENTRALIZATION_DETAILS_FAIL: `${BASE} FETCH_CENTRALIZATION_DETAILS_FAIL`,
  CHANGE_PAGE: `${BASE} CHANGE_PAGE`,
  CHANGE_SIZE: `${BASE} CHANGE_SIZE`,
  VALIDATE_DATA: `${BASE} VALIDATE_DATA`,
  VALIDATE_DATA_SUCCESS: `${BASE} VALIDATE_DATA_SUCCESS`,
  VALIDATE_DATA_FAIL: `${BASE} VALIDATE_DATA_FAIL`,
  CENTRALIZE_DATA: `${BASE} CENTRALIZE_DATA`,
  CENTRALIZE_DATA_SUCCESS: `${BASE} CENTRALIZE_DATA_SUCCESS`,
  CENTRALIZE_DATA_FAIL: `${BASE} CENTRALIZE_DATA_FAIL`,
  OPEN_CENTRALIZE_MODAL: `${BASE} OPEN_CENTRALIZE_MODAL`,
  CLOSE_CENTRALIZE_MODAL: `${BASE} CLOSE_CENTRALIZE_MODAL`,
  SHOW_NOTIFICATION: `${BASE} SHOW_NOTIFICATION`,
  HIDE_NOTIFICATION: `${BASE} HIDE_NOTIFICATION`,
  GET_COMPANY: `${BASE} GET_COMPANY`,
  GET_COMPANY_SUCCESS: `${BASE} GET_COMPANY_SUCCESS`,
  GET_COMPANY_FAIL: `${BASE} GET_COMPANY_FAIL`,
  GET_PERIOD: `${BASE} GET_PERIOD`,
  GET_PERIOD_SUCCESS: `${BASE} GET_PERIOD_SUCCESS`,
  GET_PERIOD_FAIL: `${BASE} GET_PERIOD_FAIL`,
  RESET_PAGINATION: `${BASE} RESET_PAGINATION`,
  DELETE_CENTRALIZATION: `${BASE} DELETE_CENTRALIZATION`,
  RESET_STATE: `${BASE} RESET_STATE`,
};

export default CentralizationDetailsTypes;
