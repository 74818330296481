/* eslint-disable complexity */

import React, { useEffect, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { getStateFromApi } from 'content/app.actions';
import ComponentLayout from 'content/containers/Layout/ComponentLayout';
import ConfirmationDialog from 'content/features/searchLiquidations/components/ConfirmationDialog';
import Filters from 'content/features/searchLiquidations/components/Filters';
import LoadingDialog from 'content/features/searchLiquidations/components/LoadingDialog';
import ResultsTable from 'content/features/searchLiquidations/components/ResultsTable';
import SearchForm from 'content/features/searchLiquidations/components/SearchForm';
import SimulationHeader from 'content/features/searchLiquidations/components/SimulationHeader';
import {
  changePage,
  clearFilters,
  getLiquidations,
  setSimulationStatus,
  simulateLiquidation,
  toggleConfirmationDialog,
} from 'content/features/searchLiquidations/searchLiquidations.actions';
import { useDispatch, useSelector } from 'react-redux';

function SearchLiquidations() {
  const dispatch = useDispatch();
  const {
    controls,
    loaders,
    pagination,
    sorting,
    filters,
    resultIds,
    activePeriod,
    statusMsg,
    headers,
    nextPageDialog,
    searchedCode,
    overtimeErrorDialog,
    confirmationDialog,
    simulationProcessDialog,
  } = useSelector((state) => state.searchLiquidations);

  useEffect(() => {
    dispatch(getStateFromApi());
  }, []);

  useEffect(() => {
    console.log('sorting', sorting);
    if (sorting.key) {
      dispatch(getLiquidations({ firstCall: true }));
    }
  }, [sorting.key, sorting.direction]);

  const { objects } = useSelector((state) => state.app);
  const firstFetchLiquidations = () => dispatch(getLiquidations({ firstCall: true }));

  const refreshLiquidations = () => {
    dispatch(clearFilters());
    firstFetchLiquidations();
  };

  const { liquidations, items } = objects;
  const liquidationsData = useMemo(
    () => (resultIds || []).map((id) => liquidations[id]),
    [liquidations, resultIds],
  );
  const itemsData = Object.values(items);

  const fetchLiquidations = () => {
    dispatch(getLiquidations());
  };

  const showLiquidations = controls.buildCode.length && activePeriod !== '';

  const simulateLiquidationData = (props) => {
    dispatch(
      setSimulationStatus({
        statusMsg: props.saveSimulation
          ? 'Iniciando proceso guardado'
          : 'Iniciando proceso simulación',
      }),
    );
    dispatch(simulateLiquidation({ saveSimulation: props?.saveSimulation }));
  };

  const toggleConfirmSaveDialog = (open) => {
    dispatch(toggleConfirmationDialog(open));
  };

  const filterLiquidations = () => {
    dispatch(changePage(0));
    fetchLiquidations();
  };

  return (
    <ComponentLayout>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
            Planilla de simulación
          </Typography>
        </Grid>
        <SearchForm objects={objects} fetchLiquidations={firstFetchLiquidations} />
        {showLiquidations ? (
          <>
            <SimulationHeader
              activePeriod={activePeriod}
              loaders={loaders}
              simulateLiquidationData={simulateLiquidationData}
              controls={controls}
              toggleConfirmSaveDialog={toggleConfirmSaveDialog}
            />
            <Filters filters={filters} filterLiquidations={filterLiquidations} />
            <ResultsTable
              liquidations={liquidationsData}
              items={itemsData}
              headers={headers}
              controls={controls}
              pagination={pagination}
              fetchLiquidations={fetchLiquidations}
              refreshLiquidations={refreshLiquidations}
              loaders={loaders}
              sorting={sorting}
              searchControls={controls}
              nextPageDialog={nextPageDialog}
              searchedCode={searchedCode}
              overtimeErrorDialog={overtimeErrorDialog}
            />
          </>
        ) : (
          false
        )}
      </Grid>
      <LoadingDialog open={simulationProcessDialog} loaders={loaders} statusMsg={statusMsg} />
      <ConfirmationDialog
        open={confirmationDialog}
        toggleDialog={toggleConfirmSaveDialog}
        simulateLiquidationData={simulateLiquidationData}
      />
    </ComponentLayout>
  );
}

export default SearchLiquidations;
