/* eslint-disable complexity */
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { closeSimulationprocessDialog } from '../searchLiquidations.actions';

function LoadingDialog(props) {
  const dispatch = useDispatch();
  const { loaders, statusMsg, open } = props;

  const handleCloseDialog = () => {
    dispatch(closeSimulationprocessDialog());
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogContent>
        <Grid>
          <Typography variant="h6" gutterBottom>
            {statusMsg || 'Iniciando proceso simulación'}
          </Typography>
        </Grid>
        {loaders.simulation && (
          <Grid>
            <LinearProgress />
          </Grid>
        )}
      </DialogContent>
      {!loaders.simulation && (
        <DialogActions sx={{ pt: 0 }}>
          <Button onClick={handleCloseDialog}>Cerrar</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default LoadingDialog;
