import { Button, Grid, Typography } from '@mui/material';
import ComponentLayout from 'content/containers/Layout/ComponentLayout';
import {
  assignBuildingCodeUsers,
  closeDialog,
  fetchData,
  handleUsersSelection,
  resetControls,
  toggleDialog,
} from 'content/features/BuildingCodeMaintainer/buildingCodes.actions';
import AssingUserDialog from 'content/features/BuildingCodeMaintainer/components/AssingUserDialog';
import Filters from 'content/features/BuildingCodeMaintainer/components/Filters';
import ResultsTable from 'content/features/BuildingCodeMaintainer/components/ResultsTable';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function BuildingCodeMaintainer() {
  const dispatch = useDispatch();
  const { pagination, filters, loaders, controls } = useSelector((state) => state.buildingCodes);
  const { buildingCodes, users } = useSelector((state) => state.app.objects);

  useEffect(() => {
    dispatch(fetchData({ ...pagination, ...filters }));
  }, [pagination.page, pagination.size]);

  /* eslint-disable-next-line  */
  useEffect(() => {
    return () => {
      dispatch(resetControls());
    };
  }, []);

  const handleToggleDialog = (payload) => dispatch(toggleDialog(payload));
  const onChangeAutocomplete = (payload) => dispatch(handleUsersSelection(payload));
  const handleCloseDialog = () => dispatch(closeDialog());
  const assingUsers = (payload) => dispatch(assignBuildingCodeUsers(payload));

  return (
    <ComponentLayout>
      <Grid container>
        <Grid container direction="row" justifyContent="space-between">
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Mantenedor código de obra
          </Typography>
          <Button variant="contained">Actualizar datos</Button>
        </Grid>
        <Filters filters={filters} pagination={pagination} loaders={loaders} />
        <ResultsTable
          pagination={pagination}
          data={buildingCodes}
          loaders={loaders}
          handleToggleDialog={handleToggleDialog}
        />
        <AssingUserDialog
          controls={controls}
          handleCloseDialog={handleCloseDialog}
          users={users}
          onChangeAutocomplete={onChangeAutocomplete}
          assingUsers={assingUsers}
          loaders={loaders}
        />
      </Grid>
    </ComponentLayout>
  );
}

export default BuildingCodeMaintainer;
