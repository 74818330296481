// @ts-nocheck
/* eslint-disable complexity */
/* eslint-disable react/jsx-props-no-spreading */
import { TableBody, TableCell, TableRow, TextField } from '@mui/material';
import React, { useMemo } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { mergeObjectsReducer } from 'content/app.actions';
import { formatRutV2 } from 'utils/functions';
import { setSimulationItems, toggleOvertimeFormatError } from '../searchLiquidations.actions';

const color = {
  success: '#e1ffe0',
  error: '#f7eded',
  warning: '#F0F6FE',
  default: '',
  nonEditable: '#f5f5f5',
};

function TableData(props) {
  const [cellSelected, setCellSelected] = React.useState({ employeeId: null, itemCode: null });

  const dispatch = useDispatch();
  const { liquidations, controls, searchedCode } = props;

  function currencyFormatter(value) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'CLP',
    });
    return formatter.format(Number(value));
  }

  const handleDobleClic = (employeeId, itemCode) => {
    setCellSelected({ employeeId, itemCode });
    const newLiqData = {};
    liquidations.forEach((liq) => {
      const liquidation = { ...liq };
      if (liquidation.employeeId === employeeId) {
        const newItems = liquidation.items.map((item) => ({
          ...item,
          editMode: item.item_code === itemCode,
        }));
        liquidation.items = newItems;
      }
      newLiqData[liq.id] = liquidation;
    });

    dispatch(mergeObjectsReducer({ objects: { liquidations: newLiqData } }));
  };

  const editModeOff = (liqs) => {
    const newLiqData = {};

    liqs.forEach((liq) => {
      const newItems = liq.items.map((item) => ({ ...item, editMode: false }));
      newLiqData[liq.id] = { ...liq, items: newItems };
    });
    dispatch(mergeObjectsReducer({ objects: { liquidations: newLiqData } }));
  };

  const handlePaste = ({ overtime }) => {
    /* eslint-disable no-restricted-globals */
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData('text');
    let sheetRow;

    if (overtime) {
      const fields = pastedText.split('\n').map((t) => t.replace('\r', '').split('\t'));
      const overtimeValidation = fields.map((f) => (f[0].length <= 3 ? f : ['0']));
      const toggleOvertimeError = fields.find((f) => f[0].length > 3);

      if (toggleOvertimeError?.length) {
        dispatch(toggleOvertimeFormatError(true));
      }

      sheetRow = overtimeValidation;
    } else {
      sheetRow = pastedText.split('\n').map((t) => t.split('\t'));
    }

    const idxLiqFound = liquidations.findIndex((liq) => liq.employeeId === cellSelected.employeeId);

    const newLiqData = JSON.parse(JSON.stringify(liquidations));
    let newItems = [];

    sheetRow.forEach((el, idx) => {
      const liq = newLiqData[idxLiqFound + idx];
      if (!liq) return; // TODO: todo lo que no se pudo copiar se tiene que copiar en la DB

      const idxItem = liq.items.findIndex((item) => item.item_code === cellSelected.itemCode);
      const item = liq.items[idxItem];
      const value = el[el.length - 1].replace(/\D/g, '');

      if (+item.amount === +value) return;

      item.amount = value;
      item.edited = +item.originalAmount !== +value;

      newItems = [
        ...newItems,
        { overtime, employeeId: liq.employeeId, itemCode: cellSelected.itemCode, value: +value },
      ];
    });
    dispatch(setSimulationItems(newItems));

    editModeOff(newLiqData);
  };

  const setInputValue = ({ e, employeeId, itemCode, overtime }) => {
    const { value } = e.target;

    const idxLiq = liquidations.findIndex((liq) => liq.employeeId === employeeId);
    const liquidation = liquidations[idxLiq];

    const idxItem = liquidation.items.findIndex((i) => i.item_code === itemCode);
    const item = liquidation.items[idxItem];

    const itemNoExist = idxItem < 0;
    const isEmpty = value === '';
    if (idxItem < 0) return;
    if (itemNoExist || isEmpty) {
      editModeOff(liquidations);
      return;
    }

    const isOriginalAmount = +item.originalAmount === +value;

    dispatch(
      setSimulationItems([{ overtime, employeeId, itemCode, value: +value, isOriginalAmount }]),
    );

    const newItem = { ...item };
    newItem.edited = !isOriginalAmount;
    newItem.amount = value;

    const newLiquidation = { ...liquidation, items: [...liquidation.items] };
    newLiquidation.items[idxItem] = newItem;

    const newLiqData = liquidations.map((liq, idx) => {
      if (idx === idxLiq) return newLiquidation;
      return liq;
    });

    editModeOff(newLiqData);
  };

  const handleKeyDown = ({ e, ...args }) => {
    if (e.key === 'Enter') {
      setInputValue({ e, ...args });
    }
  };

  const handleBlur = (args) => {
    setInputValue(args);
  };

  const buildCode = useMemo(
    () => controls.buildCode.find((item) => item.code === searchedCode),
    [controls.buildCode],
  );

  const valueFormatter = (value) => (value ? currencyFormatter(value) : '0');

  const defaultColor = (isEditable) => (isEditable ? '#fff' : color.nonEditable);
  const cellPropsStart = { align: 'left', sx: { backgroundColor: color.nonEditable } };
  const cellPropsEnd = { align: 'right', sx: { backgroundColor: color.nonEditable } };

  const getColor = (item) => {
    if (item.error) {
      return color.error;
    }
    if (item.edited) {
      return color.warning;
    }
    return defaultColor(item.isEditable);
  };

  return (
    <TableBody>
      {liquidations.length && buildCode ? (
        liquidations.map((liq) => (
          <TableRow
            key={liq.liquidationId}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell {...cellPropsStart}>{formatRutV2(liq.employeeRut)}</TableCell>
            <TableCell {...cellPropsStart}>{liq.employeeSurname}</TableCell>
            <TableCell {...cellPropsStart}>{liq.employeeSecondSurname}</TableCell>
            <TableCell {...cellPropsStart}>{liq.employeeName}</TableCell>
            <TableCell {...cellPropsStart}>{liq.employeeCodeSheet}</TableCell>
            <TableCell {...cellPropsStart}>{liq.employeePosition}</TableCell>
            <TableCell {...cellPropsStart}>{liq.buildCode}</TableCell>
            <TableCell {...cellPropsStart}>{liq.buildCodeName}</TableCell>
            <TableCell {...cellPropsStart}>{liq.employeeContractType}</TableCell>
            <TableCell {...cellPropsStart}>
              {liq.employeeContractStart
                ? moment(liq.employeeContractStart).format('DD-MM-YYYY')
                : `-`}
            </TableCell>
            <TableCell {...cellPropsStart}>
              {liq.employeeContractFinish
                ? moment(liq.employeeContractFinish).format('DD-MM-YYYY')
                : `-`}
            </TableCell>
            <TableCell {...cellPropsStart}>{currencyFormatter(liq.employeeBaseWage)}</TableCell>
            {liq.items.map((item) => (
              <TableCell align="right" style={{ backgroundColor: getColor(item) }}>
                {item.isEditable && item.editMode ? (
                  <TextField
                    variant="standard"
                    defaultValue=""
                    // defaultValue={item.amount}
                    autoFocus
                    onPaste={() => handlePaste({ overtime: item.overtime })}
                    onChange={(e) => {
                      let { value } = e.target;
                      const isOvertime = item.name.includes('Horas Extras');

                      if (isOvertime) {
                        value = value.replace(/\D/g, '').slice(0, 3);
                      } else {
                        value = value.replace(/\D/g, '');
                      }

                      if (e.target.value !== value) {
                        // eslint-disable-next-line no-param-reassign
                        e.target.value = value;
                      }
                    }}
                    onKeyDown={(e) =>
                      handleKeyDown({
                        e,
                        employeeId: liq.employeeId,
                        itemCode: item.item_code,
                        overtime: item.overtime,
                      })
                    }
                    onBlur={(e) =>
                      handleBlur({
                        e,
                        employeeId: liq.employeeId,
                        itemCode: item.item_code,
                        overtime: item.overtime,
                      })
                    }
                    size="small"
                    style={{ width: 100 }}
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        display: 'none',
                      },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'right' },
                      },
                    }}
                  />
                ) : (
                  <span
                    onDoubleClick={() =>
                      item.isEditable && handleDobleClic(liq.employeeId, item.item_code)
                    }>
                    {currencyFormatter(item.amount)}
                  </span>
                )}
              </TableCell>
            ))}
            {/* Bono sala cuna */}
            <TableCell {...cellPropsEnd}>{valueFormatter(liq.kinderGartenBonus)}</TableCell>
            <TableCell {...cellPropsEnd}>{valueFormatter(liq.prizeBonus)}</TableCell>
            <TableCell {...cellPropsEnd}>{valueFormatter(liq.rewardDifference)}</TableCell>
            <TableCell {...cellPropsEnd}>{valueFormatter(liq.baseWageDifference)}</TableCell>
            <TableCell {...cellPropsEnd}>{liq.workedDays}</TableCell>
            <TableCell {...cellPropsEnd}>{valueFormatter(liq.incomeGross)}</TableCell>
            <TableCell {...cellPropsEnd}>{valueFormatter(liq.uniqueTax)}</TableCell>
            <TableCell {...cellPropsEnd}>{valueFormatter(liq.totalIncomeTaxable)}</TableCell>
            <TableCell {...cellPropsEnd}>{valueFormatter(liq.taxableBase)}</TableCell>
            <TableCell {...cellPropsEnd}>{valueFormatter(liq.employeeAgreedWage)}</TableCell>
            <TableCell {...cellPropsEnd}>{valueFormatter(liq.liquidReach)}</TableCell>
            <TableCell {...cellPropsEnd}>{valueFormatter(liq.incomeNet)}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableCell align="center" colSpan={9}>
          Sin resultados.
        </TableCell>
      )}
    </TableBody>
  );
}

export default TableData;
