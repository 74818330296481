import { createAction } from '@reduxjs/toolkit';
import CompanyTypes from './companies.types';

export const fetchCompanies = createAction(CompanyTypes.FETCH_COMPANIES);
export const fetchCompaniesSuccess = createAction(
  CompanyTypes.FETCH_COMPANIES_SUCCESS,
  (payload) => ({ payload }),
);
export const fetchCompaniesFail = createAction(CompanyTypes.FETCH_COMPANIES_FAIL);

const CompanyActions = {
  fetchCompanies,
  fetchCompaniesSuccess,
  fetchCompaniesFail,
};

export default CompanyActions;
