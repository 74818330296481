/* eslint-disable complexity */

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Button,
  CardMedia,
  Grid,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import Paths from 'utils/paths';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EBCOLogo = `${window.location.origin}/images/logo-ebco.png`;
const drawerWidth = 110;

function Sidebar(props) {
  const { children, handleLogout } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = useSelector((state) => state.app.userRole);

  const handleRouteClick = (path) => {
    setMobileOpen(false);
    navigate(path);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar>
        <CardMedia
          /* onClick={goToHome} */
          component="img"
          style={{
            height: '55px',
            cursor: 'pointer',
            objectFit: 'contain',
            background: '#383B3C',
            padding: '9px 2px',
          }}
          image={EBCOLogo}
          title="TCIT"
        />
      </Toolbar>
      <Divider />
      <List>
        {Paths.protecteds
          .filter((item) => !item.hide)
          .filter(
            (route) =>
              (route.permission === 'simulation' && userRole?.permissions?.simulation) ||
              (route.permission === 'centralization' && userRole?.permissions?.centralization) ||
              route.permission === 'default',
          )
          .map(({ path, Icon, name }) => (
            <ListItem key={name} disablePadding>
              <ListItemButton
                onClick={() => handleRouteClick(path)}
                sx={
                  location.pathname === path ||
                  (location.pathname === '/liquidaciones' && path === '/simulador')
                    ? {
                        background: '#E2EDFD',
                        borderRadius: '0px 10px 10px 0px',
                        '&:hover': { background: '#E2EDFD' },
                      }
                    : { borderRadius: '0px 10px 10px 0px' }
                }>
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                    textAlign: 'center',
                  }}>
                  <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={name}
                    disableTypography
                    sx={{ color: '#818181', fontSize: '13px!important', fontWeight: 'bolder' }}
                  />
                </Grid>
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={2}
        sx={{
          width: { lg: `calc(100%` },
          background: '#fff',
        }}>
        <Toolbar sx={{ background: '#fff' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'block' } }}>
            <MenuIcon color="secondary" />
          </IconButton>
          {!isMobile && (
            <Grid container justifyContent="flex-end">
              <Button onClick={handleLogout}>
                <Grid container direction="column" alignItems="center">
                  <LogoutIcon fontSize="medium" color="secondary" />
                  <Typography
                    align="center"
                    variant="caption"
                    sx={{ color: '#818181', fontWeight: 'bold' }}>
                    Cerrar sesión
                  </Typography>
                </Grid>
              </Button>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}>
          {drawer}
        </Drawer>
        {/*  <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open>
          {drawer}
        </Drawer> */}
      </Box>
      <Grid container>{children}</Grid>
    </Box>
  );
}

export default Sidebar;
