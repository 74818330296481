import * as React from 'react';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userRoleRedirect } from 'utils/functions';
// import { TextField } from '@mui/material';

// function createData(id, name, calories, fat, carbs, protein) {
//   return { id, name, calories, fat, carbs, protein, editMode: false, edited: false };
// }

export default function Home() {
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.app.userRole);

  React.useEffect(() => {
    const redirect = userRoleRedirect(userRole);
    return navigate(redirect);
  }, [userRole]);

  // const [rows, setRows] = React.useState([
  //   createData(1, 'Emilio Andres', 159, 6.0, 24, 4.0),
  //   createData(2, 'Alfonso Alejandro', 237, 9.0, 37, 4.3),
  //   createData(3, 'Carlos Hernan', 262, 16.0, 24, 6.0),
  //   createData(4, 'Cristian Andres', 305, 3.7, 67, 4.3),
  //   createData(5, 'Fernanda Andrea', 356, 16.0, 49, 3.9),
  // ]);
  // const [cellSelected, setCellSelected] = React.useState({ row: null, col: null });

  // const handleDobleClic = (rowId, colId) => {
  //   setCellSelected({ row: rowId, col: colId });
  //   const aux = rows.map((item) => {
  //     if (item.id === rowId) {
  //       item.editMode = true;
  //     } else {
  //       item.editMode = false;
  //     }
  //     return item;
  //   });
  //   setRows(aux);
  // };

  // const editModeOff = () => {
  //   const aux = rows.map((item) => {
  //     item.editMode = false;
  //     return item;
  //   });
  //   setRows(aux);
  // };

  // const handlePaste = () => {
  //   const clipboardData = event.clipboardData || window.clipboardData;
  //   const pastedText = clipboardData.getData('text');

  //   const sheetRow = pastedText.split('\n').map((t) => t.split('\t'));

  //   const idxRowFound = rows.findIndex((item) => cellSelected.row === item.id);

  //   sheetRow.forEach((item, idx) => {
  //     if (rows[idxRowFound + idx]) {
  //       rows[idxRowFound + idx][cellSelected.col] = item[item.length - 1];
  //       rows[idxRowFound + idx].edited = true;
  //     }
  //   });
  //   // setea todo no editable
  //   editModeOff();
  // };

  // const setInputValue = (e, rowId, colId) => {
  //   const idx = rows.findIndex((item) => item.id === rowId);
  //   rows[idx][colId] = e.target.value;
  //   rows[idx].edited = true;
  //   setRows(rows);
  //   editModeOff();
  // };

  // const handleKeyDown = (e, rowId, colId) => {
  //   if (e.key === 'Enter') {
  //     setInputValue(e, rowId, colId);
  //   }
  // };

  // const handleBlur = (e, rowId, colId) => {
  //   setInputValue(e, rowId, colId);
  // };

  // const CustomInput = ({ row, colId }) => {
  //   return (
  //     <TextField
  //       variant="standard"
  //       defaultValue={row.calories}
  //       autoFocus={true}
  //       onPaste={handlePaste}
  //       onKeyDown={(e) => handleKeyDown(e, row.id, colId)}
  //       onBlur={(e) => handleBlur(e, row.id, colId)}
  //     />
  //   );
  // };

  return (
    <TableContainer component={Paper}>
      {/* <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Colaboración</TableCell>
            <TableCell align="right">Bono 1</TableCell>
            <TableCell align="right">Bono 2</TableCell>
            <TableCell align="right">Bono asistencia</TableCell>
            <TableCell align="right">Gratificación</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right" style={{ backgroundColor: row.edited ? 'orange' : '' }}>
                {row.editMode ? (
                  <CustomInput row={row} colId="calories" />
                ) : (
                  <span onDoubleClick={() => handleDobleClic(row.id, 'calories')}>
                    {row.calories}
                  </span>
                )}
              </TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> */}
    </TableContainer>
  );
}
