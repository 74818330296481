/* eslint-disable complexity */
import { Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';

function TableActions({ refreshLiquidations }) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      sx={{ p: '5px 16px 0px 10px', mb: 2 }}>
      <Grid>
        <Tooltip title="Refrescar">
          <IconButton aria-label="Refresh" onClick={refreshLiquidations}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default TableActions;
