/* eslint-disable complexity */

import React from 'react';
import { Button, Grid, Skeleton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import defaultTheme from 'utils/theme';
import {
  setSimulationStatus,
  simulateLiquidation,
  toggleConfirmationDialog,
} from '../searchLiquidations.actions';

function SimulationHeader() {
  const dispatch = useDispatch();
  const { activePeriod, loaders, controls } = useSelector((state) => state.searchLiquidations);

  const { find } = loaders;
  const { simulationItems } = controls;

  const hide = activePeriod === '' && !find;
  const loading = find;

  const simulateLiquidationData = (props) => {
    dispatch(
      setSimulationStatus({
        statusMsg: props.saveSimulation
          ? 'Iniciando proceso guardado'
          : 'Iniciando proceso simulación',
      }),
    );
    dispatch(simulateLiquidation({ saveSimulation: props?.saveSimulation }));
  };

  const toggleConfirmSaveDialog = () => {
    dispatch(toggleConfirmationDialog(true));
  };

  return (
    !hide && (
      <Grid container sx={{ mt: 4 }} alignItems="center" justifyContent="space-between">
        <Grid xs={12} md={4} lg={6}>
          {loading ? (
            <Skeleton animation="wave" width="220px" variant="text" />
          ) : (
            <Grid container>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Periodo:
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  ml: 1,
                  fontWeight: 'bold',
                  color: defaultTheme.palette.primary.main,
                  textTransform: 'capitalize',
                }}>
                {activePeriod}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid xs={12} md={7} lg={6}>
          <Grid container justifyContent="flex-end">
            <Grid
              xs={12}
              md={5}
              lg={4}
              sx={{
                paddingRight: { md: '20px', lg: '20px' },
                marginBottom: { xs: '20px', sm: '20px', md: '0px' },
                marginTop: { xs: '20px', sm: '20px', md: '0px' },
              }}>
              {loading ? (
                <Skeleton animation="wave" height="60px" />
              ) : (
                <Button
                  variant="outlined"
                  fullWidth
                  disabled={!Object.keys(simulationItems).length}
                  onClick={toggleConfirmSaveDialog}>
                  Guardar en BUK
                </Button>
              )}
            </Grid>
            <Grid xs={12} md={5} lg={4}>
              {loading ? (
                <Skeleton animation="wave" height="60px" />
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={simulateLiquidationData}
                  disabled={!Object.keys(simulationItems).length}>
                  Simular
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  );
}

export default SimulationHeader;
