import { createReducer } from '@reduxjs/toolkit';
import {
  changePage,
  changeSize,
  fetchCentralizationDetails,
  fetchCentralizationDetailsFail,
  fetchCentralizationDetailsSuccess,
  validateData,
  validateDataFail,
  validateDataSuccess,
  openCentralizeModal,
  closeCentralizeModal,
  centralizeData,
  centralizeDataSuccess,
  centralizeDataFail,
  showNotification,
  hideNotification,
  getPeriod,
  getPeriodSuccess,
  getPeriodFail,
  getCompany,
  getCompanySuccess,
  getCompanyFail,
  resetPagination,
  resetState,
} from './centralizationDetails.actions';

const initialState = {
  centralizationDetails: [],
  loading: false,
  error: null,
  pagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  record: {},
  recordLineStatus: {},
  isCentralizeModalOpen: false,
  totalBudget: {},
  notification: null,
  company: {},
  period: {},
};

export const centralizationDetailsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchCentralizationDetails, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchCentralizationDetailsSuccess, (state, action) => {
      state.centralizationDetails = action.payload.list;
      state.record = action.payload.record;
      state.totalBudget = action.payload.totalBudget;
      state.loading = false;
      state.pagination.count = action.payload.pagination.count;
      state.pagination.size = action.payload.pagination.pageSize;
      state.error = null;
    })
    .addCase(fetchCentralizationDetailsFail, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(changePage, (state, action) => {
      state.pagination.page = action.payload;
    })
    .addCase(changeSize, (state, action) => {
      state.pagination.size = action.payload;
    })
    .addCase(validateData, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(validateDataSuccess, (state) => {
      state.loading = false;
    })
    .addCase(validateDataFail, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(centralizeData, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(centralizeDataSuccess, (state, action) => {
      state.loading = false;
      state.record = action.payload.record;
    })
    .addCase(centralizeDataFail, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(openCentralizeModal, (state) => {
      state.isCentralizeModalOpen = true;
    })
    .addCase(closeCentralizeModal, (state) => {
      state.isCentralizeModalOpen = false;
    })
    .addCase(showNotification, (state, action) => {
      state.notification = { message: action.payload.message, type: action.payload.severity };
    })
    .addCase(hideNotification, (state) => {
      state.notification = null;
    })
    .addCase(getCompany, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getCompanySuccess, (state, action) => {
      state.company = action.payload;
      state.loading = false;
    })
    .addCase(getCompanyFail, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getPeriod, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getPeriodSuccess, (state, action) => {
      state.period = action.payload;
      state.loading = false;
    })
    .addCase(getPeriodFail, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(resetPagination, (state) => {
      state.pagination = {
        count: 0,
        page: 0,
        size: 10,
      };
    })
    .addCase(resetState, () => ({ ...initialState }));
});

export default centralizationDetailsReducer;
