/* eslint-disable complexity */
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CircleIcon from '@mui/icons-material/Circle';
import { useDispatch } from 'react-redux';
import { formatCurrency, isPep } from 'utils/functions';
import StatusIndicator from './StatusIndicator';
import {
  changePage,
  changeSize,
  fetchCentralizationDetails,
} from '../centralizationDetails.actions';
// import CentralizationDetailsTableSkeleton from './CentralizationDetailsTableSkeleton';

const renderStatus = (recordLineLogs) => {
  if (recordLineLogs && recordLineLogs.length > 0) {
    const firstTwoLogs = recordLineLogs.slice(0, 2);
    const allValidated = firstTwoLogs.every((log) => log.validated === true);
    if (allValidated) {
      return (
        <Box display="flex" alignItems="center">
          <CircleIcon style={{ color: 'green', fontSize: 'small' }} />
          <Typography variant="body2" style={{ marginLeft: '8px' }}>
            Pasó todas las validaciones
          </Typography>
        </Box>
      );
    }
    return (
      <Box display="flex" alignItems="center">
        <CircleIcon style={{ color: 'red', fontSize: 'small' }} />
        <Typography variant="body2" style={{ marginLeft: '8px' }}>
          No pasó todas las validaciones
        </Typography>
      </Box>
    );
  }
  return <Typography variant="body2">Sin validaciones</Typography>;
};

function Row({ row }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [row.projectCode]);

  const openRow = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment key={row.id}>
      <TableRow>
        <TableCell>
          {isPep(row.projectCode) && (
            <IconButton aria-label="expand row" size="small" onClick={openRow}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell align="right">{row.projectCode}</TableCell>
        <TableCell align="right">{formatCurrency(row.totalDebit)}</TableCell>
        <TableCell align="right">{formatCurrency(row.totalCredit)}</TableCell>
        <TableCell>{isPep(row.projectCode) && renderStatus(row.logs)}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'none' }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <StatusIndicator recordLineLogs={{ logs: row.logs }} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function AccountabilityRecordLineTable(props) {
  const dispatch = useDispatch();
  const { registryLines, pagination, accountabilityRecordId } = props;
  const { count, page, size } = pagination;

  const handleChangePage = (event, newPage) => {
    dispatch(changePage(newPage));
    dispatch(
      fetchCentralizationDetails({ page: newPage, size: pagination.size, accountabilityRecordId }),
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeSize(parseInt(event.target.value, 10)));
    dispatch(changePage(0));
    dispatch(
      fetchCentralizationDetails({
        page: 0,
        size: parseInt(event.target.value, 10),
        accountabilityRecordId,
      }),
    );
  };

  const headerStyle = {
    '& th': {
      fontWeight: 'bold',
      backgroundColor: '#F0F6FE',
    },
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow sx={headerStyle}>
              <TableCell />
              <TableCell align="right">Cód. proyecto y CECO</TableCell>
              <TableCell align="right">Debe</TableCell>
              <TableCell align="right">Haber</TableCell>
              <TableCell>Estado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registryLines && registryLines.length > 0 ? (
              registryLines.map((row) => <Row key={row.id} row={row} />)
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <Typography variant="subtitle1" gutterBottom>
                    No hay registros disponibles en BUK para centralizar.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100, { label: 'Todos', value: count }]}
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count: countParam }) => `${from}–${to} de ${countParam}`}
      />
    </Paper>
  );
}

export default AccountabilityRecordLineTable;
