/* eslint-disable complexity */
import React from 'react';
import { Grid, Paper, Table, TableContainer, TablePagination } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  changePage,
  changeSize,
  openNextPageDialog,
  resetSimulationItems,
  toggleOvertimeFormatError,
} from 'content/features/searchLiquidations/searchLiquidations.actions';
import TableActions from './TableActions';
import TableHeader from './TableHeader';
import TableData from './TableData';
import LiquidationTableSkeleton from './LiquidationTableSkeleton';
import NextPageDialog from './NextPageDialog';
import OvertimeFormatDialog from './OvertimeFormatError';

function ResultsTable(props) {
  const dispatch = useDispatch();
  const {
    liquidations,
    controls,
    pagination,
    fetchLiquidations,
    refreshLiquidations,
    loaders,
    headers,
    nextPageDialog,
    searchedCode,
    overtimeErrorDialog,
  } = props;
  const { count, page, size } = pagination;
  console.log({ count, page, size });
  const toggleDialog = (open) => {
    dispatch(openNextPageDialog(open));
  };

  const handleNextPage = (event, newPage) => {
    console.log({ newPage });
    if (Object.keys(controls.simulationItems).length > 0) {
      toggleDialog(true);
      return;
    }
    dispatch(changePage(newPage));
    fetchLiquidations();
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeSize(parseInt(event.target.value, 10)));
    dispatch(changePage(0));
    dispatch(resetSimulationItems());
    fetchLiquidations();
  };

  const seeAllRecords = () => {
    dispatch(changeSize(count));
    toggleDialog(false);
    fetchLiquidations();
  };

  const toggleOvertimeDialog = (payload) => {
    dispatch(toggleOvertimeFormatError(payload));
  };

  return (
    <>
      <TableActions refreshLiquidations={refreshLiquidations} />

      <TableContainer component={Paper} sx={{ overflowX: 'auto', margin: 0, maxHeight: '78vh' }}>
        <Table stickyHeader size="small" aria-label="simulation table">
          <TableHeader />

          {loaders.find ? (
            <LiquidationTableSkeleton headers={headers} />
          ) : (
            <TableData
              liquidations={liquidations}
              controls={controls}
              searchedCode={searchedCode}
            />
          )}
        </Table>
      </TableContainer>

      <Grid container justifyContent="flex-end">
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleNextPage}
          rowsPerPage={size}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página:"
          rowsPerPageOptions={[10, 25, 50, 100, { label: 'Todos', value: count }]}
          labelDisplayedRows={({ from, to, count: countParam }) => `${from}–${to} de ${countParam}`}
        />
      </Grid>
      <NextPageDialog
        open={nextPageDialog}
        toggleDialog={toggleDialog}
        seeAllRecords={seeAllRecords}
      />
      <OvertimeFormatDialog open={overtimeErrorDialog} toggleDialog={toggleOvertimeDialog} />
    </>
  );
}

export default ResultsTable;
