const BASE = '[SearchLiquidations]';

const SearchLiquidationsTypes = {
  FETCH_DATA: `${BASE} FETCH_DATA`,
  FETCH_DATA_SUCCESS: `${BASE} FETCH_DATA_SUCCESS`,
  FETCH_DATA_FAIL: `${BASE} FETCH_DATA_FAIL`,
  CHECK_LIQUIDATION_RESPONSE: `${BASE} CHECK_LIQUIDATION_RESPONSE`,
  CHANGE_CONTROLS: `${BASE} CHANGE_CONTROLS`,
  CHANGE_CONTROLS_SEARCH: `${BASE} CHANGE_CONTROLS_SEARCH`,
  CHANGE_PAGE: `${BASE} CHANGE_PAGE`,
  CHANGE_SIZE: `${BASE} CHANGE_SIZE`,
  CHANGE_FILTER_CONTROLS: `${BASE} CHANGE_FILTER_CONTROLS`,
  CLEAR_FILTERS: `${BASE} CLEAR_FILTERS`,
  SET_SORTING: `${BASE} SET_SORTING`,
  GET_LIQUIDATIONS_FILE: `${BASE} GET_LIQUIDATIONS_FILE`,
  GET_LIQUIDATIONS_FILE_SUCCESS: `${BASE} GET_LIQUIDATIONS_FILE_SUCCESS`,
  GET_LIQUIDATIONS_FILE_FAIL: `${BASE} GET_LIQUIDATIONS_FILE_FAIL`,
  SIMULATE_LIQUIDATION: `${BASE} SIMULATE_LIQUIDATION`,
  SIMULATE_LIQUIDATION_SUCCESS: `${BASE} SIMULATE_LIQUIDATION_SUCCESS`,
  SIMULATE_LIQUIDATION_FAIL: `${BASE} SIMULATE_LIQUIDATION_FAIL`,
  SET_SIMULATION_ITEMS: `${BASE} SET_SIMULATION_ITEMS`,
  SET_SIMULATION_STATUS: `${BASE} SET_SIMULATION_STATUS`,
  CHECK_SIMULATION_RESPONSE: `${BASE} CHECK_SIMULATION_RESPONSE`,
  CHECK_SIMULATION_RESPONSE_SUCCESS: `${BASE} CHECK_SIMULATION_RESPONSE_SUCCESS`,
  CHECK_SIMULATION_RESPONSE_FAIL: `${BASE} CHECK_SIMULATION_RESPONSE_FAIL`,
  CLOSE_SIMULATION_PROCESS_DIALOG: `${BASE} CLOSE_SIMULATION_PROCESS_DIALOG`,
  OPEN_NEXT_PAGE_DIALOG: `${BASE} OPEN_NEXT_PAGE_DIALOG`,
  TOGGLE_OVERTIME_FORMAT_ERROR: `${BASE} TOGGLE_OVERTIME_FORMAT_ERROR`,
  TOGGLE_CONFIRMATION_DIALOG: `${BASE} TOGGLE_CONFIRMATION_DIALOG`,
  RESET_SIMULATION_ITEMS: `${BASE} RESET_SIMULATION_ITEMS`,
};

export default SearchLiquidationsTypes;
