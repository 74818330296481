const BASE = '[BuildCodes]';

const BuildingCodesTypes = {
  FETCH_DATA: `${BASE} FETCH_DATA`,
  FETCH_DATA_SUCCESS: `${BASE} FETCH_DATA_SUCCESS`,
  FETCH_DATA_FAIL: `${BASE} FETCH_DATA_FAIL`,
  CHANGE_CONTROLS: `${BASE} CHANGE_CONTROLS`,
  CHANGE_PAGE: `${BASE} CHANGE_PAGE`,
  CHANGE_SIZE: `${BASE} CHANGE_SIZE`,
  CHANGE_ACTIONS: `${BASE} CHANGE_ACTIONS`,
  RESET_CONTROLS: `${BASE} RESET_CONTROLS`,
  TOGGLE_DIALOG: `${BASE} TOGGLE_DIALOG`,
  CLOSE_DIALOG: `${BASE} CLOSE_DIALOG`,
  HANDLE_USERS_SELECTION: `${BASE} HANDLE_USERS_SELECTION`,
  ASSIGN_BUILDING_CODE_USERS: `${BASE} ASSIGN_BUILDING_CODE_USERS`,
  ASSIGN_BUILDING_CODE_USERS_SUCCESS: `${BASE} ASSIGN_BUILDING_CODE_USERS_SUCCESS`,
  ASSIGN_BUILDING_CODE_USERS_FAIL: `${BASE} ASSIGN_BUILDING_CODE_USERS_FAIL`,
};

export default BuildingCodesTypes;
