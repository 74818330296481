// centralization.reducer.js
import { createReducer } from '@reduxjs/toolkit';
import {
  createCentralization,
  createCentralizationSuccess,
  createCentralizationFail,
  fetchCentralizations,
  fetchCentralizationsSuccess,
  fetchCentralizationsFail,
  openCreateCentralizationModal,
  closeCreateCentralizationModal,
  centralizationType,
  changePage,
  changeSize,
  checkCentralizationStatus,
  showError,
  hideError,
  getAllPeriods,
  getAllPeriodsSuccess,
  getAllPeriodsFail,
  setSelectCompany,
  setSelectPeriod,
  checkCentralizationExistence,
  checkCentralizationExistenceSuccess,
  checkCentralizationExistenceFail,
  resetCentralizationExistence,
} from './centralizations.actions';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  centralizations: [],
  pagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  createdRecordId: null,
  isCreateModalOpen: false,
  centralizationType: null,
  selectedCompany: {},
  periods: {},
  selectedPeriod: {},
  centralizationChecked: {},
};

const CentralizationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createCentralization, (state) => {
      state.isLoading = true;
      state.error = null;
    })

    .addCase(createCentralizationSuccess, (state, action) => {
      state.isLoading = false;
      state.createdRecordId = action.payload;
      state.error = null;
    })
    .addCase(createCentralizationFail, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(checkCentralizationStatus, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchCentralizations, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchCentralizationsSuccess, (state, action) => {
      state.centralizations = action.payload.list;
      state.isLoading = false;
      state.pagination.count = action.payload.pagination.count;
      state.pagination.size = action.payload.pagination.pageSize;
      state.createdRecordId = null;
      state.error = null;
    })
    .addCase(changePage, (state, action) => {
      state.pagination.page = action.payload;
    })
    .addCase(changeSize, (state, action) => {
      state.pagination.size = action.payload;
    })
    .addCase(fetchCentralizationsFail, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(openCreateCentralizationModal, (state) => {
      state.isCreateModalOpen = true;
    })
    .addCase(closeCreateCentralizationModal, (state) => {
      state.isCreateModalOpen = false;
      state.createdRecordId = null;
      state.centralizationType = null;
      state.selectedCompany = null;
    })
    .addCase(centralizationType, (state, action) => {
      state.centralizationType = action.payload;
    })
    .addCase(setSelectCompany, (state, action) => {
      state.selectedCompany = action.payload;
    })
    .addCase(showError, (state, action) => {
      state.error = action.payload;
    })
    .addCase(hideError, (state) => {
      state.error = null;
    })
    .addCase(getAllPeriods, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getAllPeriodsSuccess, (state, action) => {
      state.isLoading = false;
      state.periods = action.payload;
      // eslint-disable-next-line prefer-destructuring
      state.selectedPeriod = action.payload[0];
      state.error = null;
    })
    .addCase(getAllPeriodsFail, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(setSelectPeriod, (state, action) => {
      state.selectedPeriod = action.payload;
    })
    .addCase(checkCentralizationExistence, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(checkCentralizationExistenceSuccess, (state, action) => {
      state.isLoading = false;
      state.centralizationChecked = action.payload;
      state.error = 'La centralización ya existe, se le redirigió a la misma.';
    })
    .addCase(checkCentralizationExistenceFail, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(resetCentralizationExistence, (state) => {
      state.centralizationChecked = {};
    });
});

export default CentralizationReducer;
