import { takeLatest, spawn, put } from 'redux-saga/effects';

/* Project */
import apiRequest, { apiSuccess } from 'utils/api';
import { getStateFromApi, getStateFromApiSuccess, setErrorMessage } from './app.actions';

const clearStateIfIsUnauthorized = (statusCode) => {
  if (statusCode === 401) {
    localStorage.clear();
    window.location.replace(window.location.origin);
  }
};

const setToken = (token) => {
  if (token) {
    localStorage.setItem('user', JSON.stringify({ token }));
  }
};

const setCsrfToken = (csrfToken) => {
  if (csrfToken) {
    localStorage.setItem('csrfToken', csrfToken);
  }
};

function* reflectGetStateFromApi() {
  yield takeLatest(getStateFromApi, function* fetchState() {
    const response = yield apiRequest('api/v1', { method: 'get' });

    clearStateIfIsUnauthorized(response.statusCode);
    setToken(response.token);
    setCsrfToken(response.csrfToken);

    if (response.error) {
      return yield put(
        apiSuccess(setErrorMessage, { msg: 'Falló la carga inicial de información.' }),
      );
    }

    return yield put(
      getStateFromApiSuccess({
        user: response.user,
        signedByGoogle: response.signedByGoogle,
        objects: response.objects,
        userRole: response.userRole,
      }),
    );
  });
}

export default function* AppSaga() {
  yield spawn(reflectGetStateFromApi);
}
