import { createReducer } from '@reduxjs/toolkit';

import {
  assignBuildingCodeUsers,
  assignBuildingCodeUsersSuccess,
  changeControls,
  changePage,
  changeSize,
  closeDialog,
  fetchData,
  fetchDataSuccess,
  handleUsersSelection,
  resetControls,
  toggleDialog,
} from './buildingCodes.actions';

const initialState = {
  loaders: {
    page: false,
    save: false,
  },
  filters: {
    name: '',
    code: '',
  },
  pagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  controls: {
    openDialog: false,
    selectedCode: {},
    selectedUsers: [],
  },
};

const BuildingCodesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchData, (state) => {
      state.loaders.page = true;
    })
    .addCase(fetchDataSuccess, (state, action) => {
      state.loaders.page = false;
      state.pagination.count = action.payload.count;
    })
    .addCase(changePage, (state, action) => {
      state.pagination.page = action.payload;
    })
    .addCase(changeSize, (state, action) => {
      state.pagination.size = action.payload;
    })
    .addCase(changeControls, (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    })
    .addCase(resetControls, (state) => {
      state.pagination.page = 0;
      state.filters = {
        ...initialState.filters,
      };
    })
    .addCase(toggleDialog, (state, action) => {
      state.controls.openDialog = !state.controls.openDialog;
      state.controls.selectedCode = action.payload;
      state.controls.selectedUsers = action.payload.users;
    })
    .addCase(closeDialog, (state) => {
      state.controls = {
        ...initialState.controls,
      };
    })
    .addCase(handleUsersSelection, (state, action) => {
      state.controls.selectedUsers = action.payload;
    })
    .addCase(assignBuildingCodeUsers, (state) => {
      state.loaders = {
        ...state.loaders,
        save: true,
      };
    })
    .addCase(assignBuildingCodeUsersSuccess, (state) => {
      state.loaders = {
        ...state.loaders,
        save: false,
      };
      state.controls = {
        ...initialState.controls,
      };
    });
});

export default BuildingCodesReducer;
