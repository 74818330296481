import { createAction } from '@reduxjs/toolkit';
import LiquidationsTypes from './liquidations.types';

export const changeControls = createAction(
  LiquidationsTypes.CHANGE_CONTROLS,
  ({ name, value }) => ({
    payload: {
      [name]: value,
    },
  }),
);

export const addHeader = createAction(LiquidationsTypes.ADD_HEADER, (payload) => ({
  payload,
}));

export const removeHeader = createAction(LiquidationsTypes.REMOVE_HEADER, (payload) => ({
  payload,
}));

const LiquidationsActions = {
  changeControls,
  addHeader,
  removeHeader,
};

export default LiquidationsActions;
