import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';

function LiquidationTableSkeleton(props) {
  const { headers } = props;
  return (
    <TableBody>
      {[...Array(10)].map(() => (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          {headers.map(() => (
            <TableCell component="th" scope="row">
              <Skeleton animation="wave" width="100%" height="20px" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

export default LiquidationTableSkeleton;
