/* Redux Saga */
import { all } from 'redux-saga/effects';

/* Project */
import AppSaga from 'content/app.saga';
import { CounterSaga } from 'content/features/counter';
import { UserTasksSaga } from 'content/features/tasks';
import { RecoverySaga, LoginSaga, RegisterSaga } from 'content/features/authentication';
import { BuildingCodesSaga } from 'content/features/BuildingCodeMaintainer';
import { SearchLiquidationsSaga } from 'content/features/searchLiquidations';
import { CentralizationSaga } from 'content/features/centralizations';
import { CompaniesSaga } from 'content/features/companies';
import { centralizationDetailsSaga } from 'content/features/centralizationDetails';

export default function* MainSaga() {
  yield all([
    LoginSaga(),
    CounterSaga(),
    AppSaga(),
    UserTasksSaga(),
    RecoverySaga(),
    RegisterSaga(),
    BuildingCodesSaga(),
    SearchLiquidationsSaga(),
    CentralizationSaga(),
    CompaniesSaga(),
    centralizationDetailsSaga(),
  ]);
}
