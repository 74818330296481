/* eslint-disable complexity */
import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCentralizationDetails,
  validateData,
  openCentralizeModal,
  hideNotification,
  getPeriod,
  getCompany,
  resetPagination,
  deleteCentralization,
  resetState,
} from 'content/features/centralizationDetails/centralizationDetails.actions';
import { hideError } from 'content/features/centralizations/centralizations.actions';
import { useParams, useNavigate } from 'react-router-dom';
import CentralizateModal from 'content/features/centralizationDetails/components/CentralizateModal';
import { convertDate } from 'utils/functions';
import ComponentLayout from '../../containers/Layout/ComponentLayout';
import CentralizationDetailsTable from '../../features/centralizationDetails/components/CentralizationDetailsTable';

function Centralization() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: centralizationId } = useParams();
  const {
    centralizationDetails,
    loading,
    pagination,
    record,
    totalBudget,
    notification,
    period,
    company,
  } = useSelector((state) => state.centralizationDetails);
  const { error } = useSelector((state) => state.centralizations);
  const { type } = record || {};

  let capitalizedDate = '';
  let date = '';
  if (period && period.startDate) {
    date = convertDate(period.startDate);
    capitalizedDate = date.charAt(0).toUpperCase() + date.slice(1);
  }

  const { id } = {
    id: centralizationId,
  };
  const modalData = {
    date: capitalizedDate,
    type: record?.type,
    totalBudget,
  };

  useEffect(() => {
    if (centralizationId) {
      dispatch(
        fetchCentralizationDetails({
          accountabilityRecordId: centralizationId,
          page: pagination.page,
          size: pagination.size,
        }),
      );
    }

    return () => {
      dispatch(resetState());
    };
  }, [centralizationId, dispatch]);

  useEffect(() => {
    if (record && record.periodId) {
      dispatch(getPeriod({ periodId: record.periodId }));
    }
    if (record && record.companyId) {
      dispatch(getCompany({ companyId: record.companyId }));
    }
  }, [record, dispatch]);

  const handleValidateData = () => {
    dispatch(
      validateData({
        recordId: centralizationId,
        page: pagination.page,
        size: pagination.size,
      }),
    );
  };

  const handleCentralizeData = () => {
    dispatch(openCentralizeModal());
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideNotification());
    dispatch(hideError());
  };

  const handleBack = () => {
    navigate('/centralizations');
    dispatch(resetPagination());
  };

  const handleDeleteCentralization = () => {
    if (record?.id)
      dispatch(deleteCentralization({ id: record.id, goToCentralizations: handleBack }));
  };

  const buttonAction = record?.allowCentralization ? handleCentralizeData : handleValidateData;
  const buttonLabel = record?.allowCentralization ? 'Centralizar datos' : 'Validar datos';
  const buttonDisabled = buttonLabel === 'Centralizar datos' && !!record?.centralized;
  // const buttonAction = handleValidateData;
  // const buttonLabel = 'Validar datos';
  // const buttonDisabled = false;

  return (
    <ComponentLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            onClick={handleBack}
            sx={{ marginBottom: 2, color: 'black' }}
            startIcon={<ArrowBackIcon sx={{ color: '#2F80ED' }} />}>
            Volver
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
            Centralizar
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2, marginTop: 2 }}>
            Para realizar una centralización es fundamental validar los datos. Una vez que todos los
            datos estén confirmados se habilitará el botón para centralizarlos.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box alignItems="center" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Paper elevation={0} sx={{ padding: 2, flexGrow: 1 }}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={3}>
                  <Typography color="textSecondary">ID de centralización</Typography>
                  <Typography>{id}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography color="textSecondary">Tipo de centralización</Typography>
                  <Typography>{type}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography color="textSecondary">Empresa</Typography>
                  <Typography>{company.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography color="textSecondary">Periodo</Typography>
                  <Typography>{date}</Typography>
                </Grid>
              </Grid>
            </Paper>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {loading ? (
                <Box sx={{ mr: 6 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <>
                  {!record?.centralized && (
                    <Button
                      color="error"
                      onClick={handleDeleteCentralization}
                      sx={{ mr: 1 }}
                      variant="contained">
                      Eliminar centralización
                    </Button>
                  )}
                  <Button
                    onClick={buttonAction}
                    disabled={buttonDisabled}
                    sx={{ mr: 1 }}
                    variant="contained">
                    {buttonLabel}
                  </Button>
                </>
              )}

              <CentralizateModal data={modalData} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CentralizationDetailsTable
            registryLines={centralizationDetails}
            pagination={pagination}
            loading={loading}
            accountabilityRecordId={centralizationId}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={notification || error}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseSnackbar} severity={error ? 'warning' : notification?.type}>
          {error || notification?.message}
        </Alert>
      </Snackbar>
    </ComponentLayout>
  );
}

export default Centralization;
