import { CircularProgress, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import React from 'react';

function LoginLoader(props) {
  const { open } = props;

  return (
    <Dialog fullWidth maxWidth="xs" open={open} fullScreen>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          sx={{ height: '100%' }}>
          <CircularProgress size={100} sx={{ marginBottom: '30px' }} />
          <Typography variant="h4" gutterBottom>
            Iniciando sesión
          </Typography>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default LoginLoader;
