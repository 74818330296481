import { createAction } from '@reduxjs/toolkit';
import SearchLiquidationsTypes from './searchLiquidations.types';

export const getLiquidations = createAction(SearchLiquidationsTypes.FETCH_DATA, (payload) => ({
  payload,
}));
export const getLiquidationsSuccess = createAction(
  SearchLiquidationsTypes.FETCH_DATA_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);
export const getLiquidationsFail = createAction(SearchLiquidationsTypes.FETCH_DATA_FAIL);
export const checkLiquidationResponse = createAction(
  SearchLiquidationsTypes.CHECK_LIQUIDATION_RESPONSE,
  (payload) => ({ payload }),
);

export const changeControls = createAction(
  SearchLiquidationsTypes.CHANGE_CONTROLS,
  ({ name, value }) => ({
    payload: {
      [name]: value,
    },
  }),
);

export const changeControlsSearch = createAction(
  SearchLiquidationsTypes.CHANGE_CONTROLS_SEARCH,
  ({ name, value }) => ({
    payload: {
      [name]: value,
    },
  }),
);

export const changePage = createAction(SearchLiquidationsTypes.CHANGE_PAGE, (payload) => ({
  payload,
}));
export const changeSize = createAction(SearchLiquidationsTypes.CHANGE_SIZE, (payload) => ({
  payload,
}));

export const changeFilterControls = createAction(
  SearchLiquidationsTypes.CHANGE_FILTER_CONTROLS,
  ({ name, value }) => ({
    payload: {
      [name]: value,
    },
  }),
);

export const clearFilters = createAction(SearchLiquidationsTypes.CLEAR_FILTERS);

export const setSorting = createAction(SearchLiquidationsTypes.SET_SORTING, (payload) => ({
  payload,
}));

export const getLiquidationsFile = createAction(
  SearchLiquidationsTypes.GET_LIQUIDATIONS_FILE,
  (payload) => ({
    payload,
  }),
);
export const getLiquidationsFileSuccess = createAction(
  SearchLiquidationsTypes.GET_LIQUIDATIONS_FILE_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const getLiquidationsFileFail = createAction(
  SearchLiquidationsTypes.GET_LIQUIDATIONS_FILE_FAIL,
  (payload) => ({
    payload,
  }),
);

export const simulateLiquidation = createAction(
  SearchLiquidationsTypes.SIMULATE_LIQUIDATION,
  (payload) => ({
    payload,
  }),
);
export const simulateLiquidationSuccess = createAction(
  SearchLiquidationsTypes.SIMULATE_LIQUIDATION_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);
export const simulateLiquidationFail = createAction(
  SearchLiquidationsTypes.SIMULATE_LIQUIDATION_FAIL,
);

export const setSimulationItems = createAction(
  SearchLiquidationsTypes.SET_SIMULATION_ITEMS,
  (payload) => ({
    payload,
  }),
);

export const setSimulationStatus = createAction(
  SearchLiquidationsTypes.SET_SIMULATION_STATUS,
  (payload) => ({
    payload,
  }),
);

export const checkSimulationResponse = createAction(
  SearchLiquidationsTypes.CHECK_SIMULATION_RESPONSE,
  (payload) => ({
    payload,
  }),
);

export const checkSimulationResponseSuccess = createAction(
  SearchLiquidationsTypes.CHECK_SIMULATION_RESPONSE_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const checkSimulationResponseFail = createAction(
  SearchLiquidationsTypes.CHECK_SIMULATION_RESPONSE_FAIL,
  (payload) => ({
    payload,
  }),
);

export const closeSimulationprocessDialog = createAction(
  SearchLiquidationsTypes.CLOSE_SIMULATION_PROCESS_DIALOG,
);

export const openNextPageDialog = createAction(
  SearchLiquidationsTypes.OPEN_NEXT_PAGE_DIALOG,
  (payload) => ({
    payload,
  }),
);

export const toggleOvertimeFormatError = createAction(
  SearchLiquidationsTypes.TOGGLE_OVERTIME_FORMAT_ERROR,
  (payload) => ({
    payload,
  }),
);

export const toggleConfirmationDialog = createAction(
  SearchLiquidationsTypes.TOGGLE_CONFIRMATION_DIALOG,
  (payload) => ({
    payload,
  }),
);

export const resetSimulationItems = createAction(SearchLiquidationsTypes.RESET_SIMULATION_ITEMS);

const SearchLiquidationsActions = {
  getLiquidations,
  getLiquidationsSuccess,
  getLiquidationsFail,
  changeControls,
  changePage,
  changeSize,
  changeFilterControls,
  clearFilters,
  setSorting,
  getLiquidationsFile,
  simulateLiquidation,
  simulateLiquidationSuccess,
  simulateLiquidationFail,
  setSimulationItems,
  setSimulationStatus,
  checkSimulationResponse,
  checkSimulationResponseSuccess,
  checkSimulationResponseFail,
  closeSimulationprocessDialog,
  openNextPageDialog,
  toggleOvertimeFormatError,
  toggleConfirmationDialog,
};

export default SearchLiquidationsActions;
